import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaService } from '../service/media.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MediaPanelComponent } from '../media-panel/media-panel.component';
import { ResponsePojo } from '../model/ResponsePojo';
import { MethodUtil } from '../utility/methodUtil';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { GlobalVariable } from '../model/GlobalVariable';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
  @Input() requiredFileType: string;
  @Input() component: String;
  @Input() fileType: string;
  @Input() frase: string;
  response: ResponsePojo;
  fileName = '';
  uploadProgress: number;
  uploadCompleted: boolean;
  uploadSub: Subscription;
  endPoint = "/portal/media"
  myFiles: string[] = [];
  mediaPanelComponent: MediaPanelComponent;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value = 50;
  constructor(private service: MediaService, private http: HttpClient, private snackBar: MatSnackBar, private router: Router) { }


  ngOnInit(): void {
  }


  onFileSelected(event) {
    //this.component = MethodUtil.retriveEventoNameComponent(this.component);

    // if (GlobalVariable.eventoNameComponent != null) {
    //   this.component = GlobalVariable.eventoNameComponent;
    //   this.component = this.component.trim();
    //   this.component =  this.component.replace(/\s/g, "");
    // }

    const formData = new FormData();

    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("media", this.myFiles[i]);
    }

    this.service.addMedia(formData, this.component, this.fileType).subscribe(data => {
      this.response = data;

      if (this.response.messaggio) {
        //document.getElementById("loader").style.display = "none";
        this.openSnackBar(this.response.messaggio);
        MethodUtil.customDelay();
      }

      if (this.response.error) {
        //document.getElementById("loader").style.display = "none";
        this.openSnackBarKO(this.response.error);
        MethodUtil.customDelay();
      }
      err => this.openSnackBarKO(this.response.error);
      MethodUtil.customDelay();
    });
    ;
  }


  openSnackBar(messaggio: string) {
    //this.snackBar.open("Upload completato con successo",null,this.configSuccess);
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  private configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.fileName = "";
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }

  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };

}

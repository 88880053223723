import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalVariable } from '../model/GlobalVariable';
import { ResponsePojo } from '../model/ResponsePojo';
import { SorteggioPojo } from '../model/SorteggioPojo';

@Injectable({
  providedIn: 'root'
})
export class SorteggioService {

  constructor(private http : HttpClient) { }

  tokenStr = 'Bearer ' +  GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization",this.tokenStr);
  endPoint = "/portal/sorteggio"

  getAllSorteggi(): Observable<SorteggioPojo[]>{
      //TODO: aggiungi =>{headers}
    return this.http.get<SorteggioPojo[]>(GlobalVariable.basedUrl+this.endPoint+"/getAllSorteggi/", );
    }

    addSorteggio(sorteggio : SorteggioPojo): Observable<ResponsePojo>{
      //TODO: aggiungi =>{headers}
      return this.http.post<ResponsePojo>(GlobalVariable.basedUrl+this.endPoint+"/addSorteggio",sorteggio);
    }

    editSorteggio(sorteggio : SorteggioPojo): Observable<ResponsePojo>{        
      //TODO: aggiungi =>{headers}
      return this.http.post<ResponsePojo>(GlobalVariable.basedUrl+this.endPoint+"/editSorteggio", sorteggio);
    }

    deleteSorteggioById(id:number) : Observable<Object>{
       //TODO: aggiungi =>{headers}
    return this.http.delete<ResponsePojo>(`${GlobalVariable.basedUrl+this.endPoint+"/deleteSorteggioById"}/${id}`);
    }    
}

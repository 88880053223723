<app-menu></app-menu>
<h1 style="text-align: center;"> Aggiungi Evento</h1>

<div class="col-md-3 offset-md-3" style="overflow-y: scroll; height: 550px;">

    <form (ngSubmit)="onSubmitEvento()">
        <div class="form-group">
            <label>Nome Evento</label>
            <input type="text" class="form-control" id="nome" [(ngModel)]="evento.nome" name="nome" #nomep>
        </div>

        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" (dateInput)="setDate($event)">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="form-group">
            <label>Ora Evento</label>
            <input type="text" class="form-control" id="ora" [(ngModel)]="evento.ora" name="ora">
        </div>
        <div class="form-group">
            <label>Luogo Evento</label>
            <input type="text" class="form-control" id="luogo" [(ngModel)]="evento.luogo" name="luogo">
        </div>
        <br>
        <button id="saveEventButton" class="btn btn-success" type="submit">Submit</button>
    </form>
</div>
<!-- <div id="mediaDiv" style="display: none"> -->
<div id="mediaDiv">
    <h2 style="text-align: center;"> Upload file</h2>
    <mat-accordion>
        <mat-expansion-panel (opened)="getAllMedia('FOTO')" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Carica qui le foto
                </mat-panel-title>
                <mat-panel-description>
                    <p></p>
                    <mat-icon>photo_camera</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <app-upload-file requiredFileType="image/*" fileType="FOTO" frase="Nessuna foto selezionata"
                component={{component}}></app-upload-file>
            <br>

            <!-- <mat-grid-list cols="4" rowHeight="300px">
                <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                    <mat-card class="example-card"> -->
            <div class="cdk-virtual-scroll-data-source-example">
                <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="50" class="example-viewport">

                    <div *cdkVirtualFor="let file of fileList" class="example-item">

                        <mat-card>

                            <mat-card-header>
                                <mat-card-title>{{file.nome}}</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image src={{file.url}} alt={{file.nome}}>
                            <mat-card-actions>
                                <button mat-button (click)="deleteMedia(file.nome,'FOTO')">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
            <!--  </mat-grid-tile>
            </mat-grid-list> -->
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="getAllMedia('VIDEO')" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Carica qui i video
                </mat-panel-title>
                <mat-panel-description>
                    <p></p>
                    <mat-icon>videocam</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <app-upload-file requiredFileType="video/*" fileType="VIDEO" frase="Nessun video selezionato"
                component={{component}}></app-upload-file>
            <br>
            <mat-grid-list cols="4" rowHeight="600px">
                <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                    <mat-card class="example-card">
                        <mat-card-header>
                            <mat-card-title>{{file.nome}}</mat-card-title>
                        </mat-card-header>
                        <video width="250" controls>
                            <source src={{file.url}}>
                        </video>
                        <mat-card-actions>
                            <button mat-button (click)="deleteMedia(file.nome,'VIDEO').click">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
    </mat-accordion>

    <br>
     <app-testi-list component={{nomeEventoXTest}}></app-testi-list> 


  <!--  <h2 style="text-align: center;"> Testi</h2>
    <a (click)="openDialog()" class="nav-link">Aggiungi Testi</a>
    <table class="table table-striped">
        <thead>
            <tr>
                <th>Testo</th>
                <th>Lingua</th>
                <th>Modifica/Cancella Testo</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let testi of testiList">
                <td>{{testi.testo}}</td>
                <td>{{testi.nomeLingua}}</td>
                <td>
                    <button (click)="deteleTesto(testi.id)" class="btn btn-danger" style="margin-left: 10px;"> Delete
                    </button>
                </td>
            </tr>
        </tbody>
    </table>-->




    <!-- <app-media-panel-two-media component="Eventi"></app-media-panel-two-media>-->
    <br> <br> <br> <br> <br>
    <!-- <app-notifiche></app-notifiche>-->

</div>
<br>
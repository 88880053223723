import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Testi } from 'src/app/model/Testi';
import { TestiListService } from 'src/app/service/testi-list.service';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { MethodUtil } from 'src/app/utility/methodUtil';
import { LinguaService } from 'src/app/service/lingua.service';
import { LinguaPojo } from 'src/app/model/LinguaPojo';
import { GlobalVariable } from 'src/app/model/GlobalVariable';

@Component({
  selector: 'app-edit-testi',
  templateUrl: './edit-testi.component.html',
  styleUrls: ['./edit-testi.component.css']
})
export class EditTestiComponent implements OnInit {

  testo: Testi = new Testi();
  linguaList: LinguaPojo[];
  lingua: LinguaPojo = new LinguaPojo();
  res: ResponsePojo = new ResponsePojo();
  constructor(private service: TestiListService, private linguaService: LinguaService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.testo.id = this.route.snapshot.params['id'];
    this.testo.area = this.route.snapshot.params['area'];
    this.testo.testo = this.route.snapshot.params['testo'];
    this.testo.nomeLingua = this.route.snapshot.params['nomeLingua'];
    this.testo.codLingua = this.route.snapshot.params['codLingua'];
    // this.testo.nomeLingua = GlobalVariable.lingua.nomeLingua;
    this.testo.lingua = GlobalVariable.lingua;
    this.getAllLingue();
  }

  editTesti(): ResponsePojo {
    this.res = new ResponsePojo();
    this.service.editTesto(this.testo).subscribe(data => {
      console.log("editTesti : " + data);
      data => this.res.messaggio;
      this.goToTesti();
    },
      error => this.res.error);
    return this.res;
  }

  private getAllLingue() {
    this.linguaService.getAllLingue().subscribe(data => {
      this.linguaList = data;
      console.log("linguaList : " + data);
    })
  }

  goToTesti() {
    this.router.navigate([MethodUtil.previousLink(this.testo.area)]);
  }

  onSubmit() {
    console.log(this.testo)
    this.editTesti();
  }


}

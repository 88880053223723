import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifichePojo } from 'src/app/model/NotifichePojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { LinguaService } from 'src/app/service/lingua.service';
import { NotificheService } from 'src/app/service/notifiche.service';
import { MethodUtil } from 'src/app/utility/methodUtil';
import { Location } from '@angular/common'
import { LinguaPojo } from 'src/app/model/LinguaPojo';
import { GlobalVariable } from 'src/app/model/GlobalVariable';
@Component({
  selector: 'app-add-notifiche',
  templateUrl: './add-notifiche.component.html',
  styleUrls: ['./add-notifiche.component.css']
})
export class AddNotificheComponent implements OnInit {
  notifica: NotifichePojo = new NotifichePojo();
  linguaList: LinguaPojo[];
  res: ResponsePojo = new ResponsePojo();
  component: string;
  constructor(private service: NotificheService, private location: Location, private linguaService: LinguaService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAllLingue();
    this.notifica.area = MethodUtil.retriveEventoNameComponent(GlobalVariable.component);
  }

  addNotifica(): ResponsePojo {
    this.service.addNotifica(this.notifica).subscribe((data: ResponsePojo) => {
      console.log("addTesti : " + data);
      this.res = data;
      this.goToNotifiche();
    },
      error => console.log(error));

    return this.res;
  }


  private getAllLingue() {
    this.linguaService.getAllLingue().subscribe(data => {
      this.linguaList = data;
      console.log("linguaList : " + data);
    })
  }

  onSubmit() {
    console.log(this.addNotifica)
    this.addNotifica();
  }
  goToNotifiche() {
    this.location.back();
    if (this.res.messaggio) {
      this.openSnackBarOK(this.res.messaggio);
    } else {
      this.openSnackBarKO(this.res.error)
    }
  }




  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };
}

<app-menu></app-menu>
<br>
<h2 style="text-align: center;"> Configurazione</h2>
<p style="text-align: center;"> In questa sezione potrai aggiungere delle configurazioni come: aggiiungere le lingue da selezionare per i testi.</p>
<app-lingua></app-lingua>

<h2 style="text-align: center;"> Foto premi sorteggio</h2>

<h4 style="text-align: center;"> Upload Foto</h4>
        <mat-accordion>
            <mat-expansion-panel (opened)="getAllMedia('Sorteggio','FOTO')" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Carica qui le foto
                    </mat-panel-title>
                    <mat-panel-description>
                        <p></p>
                        <mat-icon>photo_camera</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <app-upload-file requiredFileType="image/*" fileType="FOTO" frase="Nessuna foto selezionata"
                    component="Sorteggio"></app-upload-file>
                <br>
                <mat-grid-list cols="4" rowHeight="800px">
                    <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                        <mat-card class="example-card">
                            <mat-card-header>
                                <mat-card-title>{{file.nome}}</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image src={{file.url}} alt={{file.nome}}>
                            <mat-card-actions>
                                <button mat-button (click)="deleteMedia('Sorteggio',file.nome,'FOTO')">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-expansion-panel>
        </mat-accordion>
        <br>

        <h2 style="text-align: center;"> Foto Offerte</h2>

<h4 style="text-align: center;"> Upload Foto</h4>
        <mat-accordion>
            <mat-expansion-panel (opened)="getAllMedia('Offerta','FOTO')" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Carica qui le foto
                    </mat-panel-title>
                    <mat-panel-description>
                        <p></p>
                        <mat-icon>photo_camera</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <app-upload-file requiredFileType="image/*" fileType="FOTO" frase="Nessuna foto selezionata"
                    component="Offerta"></app-upload-file>
                <br>
                <mat-grid-list cols="4" rowHeight="800px">
                    <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                        <mat-card class="example-card">
                            <mat-card-header>
                                <mat-card-title>{{file.nome}}</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image src={{file.url}} alt={{file.nome}}>
                            <mat-card-actions>
                                <button mat-button (click)="deleteMedia('Offerta',file.nome,'FOTO')">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-expansion-panel>
        </mat-accordion>
        <br>
        <br>
        <br>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientiPojo } from '../model/ClientiPojo';
import { GlobalVariable } from '../model/GlobalVariable';
import { SorteggioPojo } from '../model/SorteggioPojo';

@Injectable({
  providedIn: 'root'
})
export class ClientiService {
  constructor(private http : HttpClient) { }

  tokenStr = 'Bearer ' +  GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization",this.tokenStr);
  endPoint = "/portal/reg-clienti"

  getAllClientiAvailable(): Observable<ClientiPojo[]>{
      //TODO: aggiungi =>{headers}
    return this.http.get<ClientiPojo[]>(GlobalVariable.basedUrl+this.endPoint+"/getAllClientiAvailable/", );
    }

}

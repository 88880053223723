import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EventiPojo } from 'src/app/model/EventiPojo';
import { EventiServiceService } from 'src/app/service/eventi-service.service';
import { LinguaService } from 'src/app/service/lingua.service';
import { TestiListService } from 'src/app/service/testi-list.service';
import { MethodUtil } from 'src/app/utility/methodUtil';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { GlobalVariable } from 'src/app/model/GlobalVariable';
import { ResponsePojoEventi } from 'src/app/model/ResponsePojoEventi';
import { DownloadMediaPojo } from 'src/app/model/DownloadMediaPojo';
import { MediaService } from 'src/app/service/media.service';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { Testi } from 'src/app/model/Testi';
import { LinguaPojo } from 'src/app/model/LinguaPojo';
import { MatDialog } from '@angular/material/dialog';
import { AddTestiPopUpComponent } from 'src/app/add-testi-pop-up/add-testi-pop-up.component';
import { EditTestiPopUpComponent } from 'src/app/edit-testi-pop-up/edit-testi-pop-up.component';


@Component({
  selector: 'app-add-eventi',
  templateUrl: './add-eventi.component.html',
  styleUrls: ['./add-eventi.component.css']
})
export class AddEventiComponent implements OnInit {
  resEv: ResponsePojoEventi = new ResponsePojoEventi();
  res: ResponsePojo = new ResponsePojo();
  evento: EventiPojo = new EventiPojo();
  eventoNameComponent: string;
  dataEvento: Date | null = null;
  nomeEventoXTest: String;
  public panelOpenState: boolean;
  @Input() component: String;
  fileList: DownloadMediaPojo[];
  messaggo: string;
  testo: Testi = new Testi();
  testiList: Testi[];
  linguaList: LinguaPojo[];
  idEventoMedia: string;
  idEventoTesto: string;

  constructor(private linguaService: LinguaService, private serviceTesti: TestiListService, private serviceEventi: EventiServiceService, private mediaService: MediaService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {


    const isSavedEvento = localStorage.getItem('isSavedEvento');
    if (isSavedEvento != "true") {
      document.getElementById("mediaDiv").style.display = "none";
    } else {
      this.retrieveEventoOverTheSession(this.evento);
      document.getElementById("mediaDiv").style.display = "inline";
      document.getElementById("saveEventButton").style.display = "none";
    }


    this.idEventoMedia = localStorage.getItem('idEvento')
    if (this.idEventoMedia) {
      let id = this.idEventoMedia;
      this.evento.id = id.valueOf();
    }

    
  //  this.getTestiList(MethodUtil.removeDoubleQuotes(localStorage.getItem('idEventoTesto')).toString());
    

  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    this.dataEvento = event.value;
  }

  addEventi(): ResponsePojoEventi {
    const nomeEventoXTest = localStorage.setItem('nomeEventoXTest', JSON.stringify(this.evento.nome));
    const nomeEvento = 'Eventi-' + this.evento.nome;
    localStorage.setItem('nomeEvento', JSON.stringify(nomeEvento));
    this.evento.dataEvento = this.dataEvento;
    this.serviceEventi.addEvento(this.evento).subscribe((data: ResponsePojoEventi) => {
      console.log("addEvento : " + data);
      this.resEv = data;
      this.openSnackBarOK(this.resEv.messaggio);
      GlobalVariable.eventoNameComponent = 'Eventi-' + this.resEv.id;
      this.idEventoMedia = 'Eventi-' + this.resEv.id;
      localStorage.setItem('idEvento', JSON.stringify(this.idEventoMedia));
      localStorage.setItem('isSavedEvento', JSON.stringify(true));
      const idEventoTesto = this.resEv.id.toString();
      localStorage.setItem('idEventoTesto', JSON.stringify(idEventoTesto));
      this.idEventoTesto = localStorage.getItem('idEventoTesto');
      localStorage.getItem('isSavedEvento');
      this.saveEventoOverTheSession(this.evento);
      document.getElementById("mediaDiv").style.display = "inline";
      document.getElementById("saveEventButton").style.display = "none";

      this.goToEventi();
    },
      error => console.log(error));

    return this.resEv;
  }

  onSubmitEvento() {
    console.log(this.evento)
    this.addEventi();
  }

  goToEventi() {
    this.router.navigate([MethodUtil.previousLink("eventi")]);
    if (this.resEv.messaggio) {
      this.openSnackBarOK(this.resEv.messaggio);
    } else {
      this.openSnackBarKO(this.resEv.error)
    }
  }


  //------ MEDIA STRART------
  getAllMedia(mediaType: string) {
    this.idEventoMedia = localStorage.getItem('idEvento')
    if (this.idEventoMedia) {
      this.idEventoMedia.replace("'\'", "");
      this.component = this.idEventoMedia;
    }
    this.mediaService.getAllMedia(this.component, mediaType).subscribe(data => {
      this.fileList = data;
      console.log("this.fileList MediaPanelComponent = " + this.fileList)
      err => console.log("errore : " + err)
    });
  }


  deleteMedia(nomeMedia: string, mediaType: string) {
    this.idEventoMedia = localStorage.getItem('idEvento')
    this.mediaService.deteleMedia(this.idEventoMedia, nomeMedia, mediaType).subscribe(data => {
      console.log("deleteMedia " + "nomeMedia : " + nomeMedia + " mediaType : " + mediaType)
      this.res = data;
      console.log("data : " + data)
      console.log("this.messaggo : " + this.res.messaggio)
      if (this.res.messaggio) {
        this.openSnackBarOK(this.res.messaggio);
        MethodUtil.customDelay();
      }
      err => this.openSnackBarKO(err);
      MethodUtil.customDelay();
    });
  }


  openMediaSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio + " cancellato con successo", "", this.configSuccess);
  }

  openMediaSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  checkNavigationPath() {
    let path = this.router.getCurrentNavigation();
    console.log(path);
  }


  //----MEDIA END-----


  //------Testi component Start 


  openDialog(): void {
    this.clearTesti();
    //this.testo.eventoId = MethodUtil.removeDoubleQuotes(localStorage.getItem('nomeEvento'));
    const dialogRef = this.dialog.open(AddTestiPopUpComponent, {
      width: '400px',
      height: '600px',
      data: this.testo
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.addTesti();

    });
  }
  clearTesti() {
    //  this.testo.id = null;
    this.testo.lingua = null;
    this.testo.nomeLingua = null;
    this.testo.testo = null;
  }


  addTesti(): ResponsePojo {
    //TODO: aggiungere id Evento al model testi, creare un api getTestoByEventoId
    this.testo.eventoId = MethodUtil.removeDoubleQuotes(localStorage.getItem('idEventoTesto'));
    this.testo.area = MethodUtil.removeDoubleQuotes(localStorage.getItem('nomeEvento'));
    this.testo.nomeLingua =this.testo.lingua.nomeLingua
    this.testo.codLingua =this.testo.lingua.codLingua
    this.testo.area = this.evento.nome
    localStorage.setItem('nomeLingua', JSON.stringify(this.testo.lingua.nomeLingua));
    localStorage.setItem('codLingua', JSON.stringify(this.testo.lingua.codLingua));
    localStorage.setItem('idLingua', JSON.stringify(this.testo.lingua.id));
    this.serviceTesti.addTesto(this.testo).subscribe((data: ResponsePojo) => {
      console.log("addTesti : " + JSON.stringify(data));
      this.res = data;
      this.openSnackBarOK(this.res.messaggio);
      this.getTestiList( this.testo.eventoId.toString());
    },
      error => console.log(error));
    return this.res;
  }
  //Add updateTesti
  onSubmit() {
    console.log("Testio : "+this.testo)
    this.addTesti();
  }



  private getAllLingue() {
    this.linguaService.getAllLingue().subscribe(data => {
      this.linguaList = data;
      console.log("linguaList : " + JSON.stringify(data));
    })
  }

  getTestiList( eventoId: string) {
    this.serviceTesti.getTestiListEventoId( eventoId).subscribe(data => {
      this.testiList = data;
      console.log("testiList : " + JSON.stringify(data));
    })
  }


  openDialogEditTesti(testo:Testi): void {
   // this.testo.area = MethodUtil.removeDoubleQuotes(this.evento.nome);
  //  testo.lingua.nomeLingua = MethodUtil.removeDoubleQuotes(localStorage.getItem('nomeLingua'));
  //  testo.lingua.codLingua = MethodUtil.removeDoubleQuotes(localStorage.getItem('codLingua'));
  //  testo.lingua.id = Number(MethodUtil.removeDoubleQuotes(localStorage.getItem('idLingua')));
    const dialogRef = this.dialog.open(EditTestiPopUpComponent, {
      width: '400px',
      height: '600px',
      data: testo
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.editTesti(testo);

    });
  }


  editTesti(testo:Testi) {
    this.res = new ResponsePojo();
    this.serviceTesti.editTesto(testo).subscribe(data => {
      console.log("editTesti : " + JSON.stringify(data));
      this.res = data;
      this.openSnackBarOK(this.res.messaggio);
      this.getTestiList( this.testo.eventoId.toString());
    },
      error => this.res.error);
  }

  deteleTesto(testoId: number) {
    this.serviceTesti.deteleTesto(testoId).subscribe(data => {
      console.log("testiDaCancellare : " + JSON.stringify(data));
      this.res = data;
      this.openSnackBarOK(this.res.messaggio);
      this.getTestiList( this.testo.eventoId.toString());
    },
      error => this.res.error
    );
  }

  //------Testi Component End----

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };






  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }




  private saveEventoOverTheSession(evento: EventiPojo) {
    localStorage.setItem('eventoId', JSON.stringify(this.evento.id));
    localStorage.setItem('nomeEvento', JSON.stringify(this.evento.nome));
    localStorage.setItem('descrizioneEvento', JSON.stringify(this.evento.descrizione));
    localStorage.setItem('dataEvento', JSON.stringify(this.evento.dataEvento));
    localStorage.setItem('oraEvento', JSON.stringify(this.evento.ora));
    localStorage.setItem('luogoEvento', JSON.stringify(this.evento.luogo));
  }


  private retrieveEventoOverTheSession(evento: EventiPojo) {
    this.evento.nome = MethodUtil.removeDoubleQuotes(localStorage.getItem('nomeEvento')).toString();
    this.evento.descrizione = MethodUtil.removeDoubleQuotes(localStorage.getItem('descrizioneEvento')).toString();
    this.evento.dataEvento = new Date(localStorage.getItem('dataEvento'));
    this.evento.ora = MethodUtil.removeDoubleQuotes(localStorage.getItem('oraEvento')).toString();
    this.evento.luogo = MethodUtil.removeDoubleQuotes(localStorage.getItem('luogoEvento')).toString();
  }


}


<app-menu></app-menu>

<div class="col-md-8 offset-md-3" style="height: 1000px;">

    <h3> Modifica Sorteggio </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Nome Sorteggio</label>
            <input type="text" class="form-control" id="nomeSorteggio" [(ngModel)]="sorteggio.nomeSorteggio"
                name="nomeSorteggio">
        </div>
        <div class="form-group">
            <label>Attiva Sorteggio</label>
            <input type="checkbox" class="form-control" id="sorteggioAttivo" name="sorteggioAttivo"
                [(ngModel)]="sorteggio.sorteggioAttivo" checked="checked">
        </div>
        <div id=vicitoreRandomCheck class="form-group">
            <label>Vincitore Random</label>
            <input type="checkbox" class="form-control" id=vicitoreRandomCheck [(ngModel)]="sorteggio.vincitoreRandom"
                name="vincitoreRandom">
        </div>

        <div class="form-group">
            <label style="color: red;text-align: center;">Attulale vincitore </label><br>
            <label style="color: red;text-align: center;"> <b>Cliente:</b> {{nomeVincitore}} {{cognomeVincitore}}
                </label>

        </div>

        <div id=vicitoreRandom class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Modifica vincitore</mat-label>
                <mat-select [(value)]="sorteggio.cliente">
                    <mat-option *ngFor="let client of clientiList" [value]="client">
                        <b>Cliente:</b> {{client.nome}} {{client.cognome}} 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div id="clienteNotPresent" style="display: none;">
            <label style="color: red;text-align: center;">!!! ATTENZIONE !!! - Nessun cliente disponibile</label>
        </div>


        <!--Da raggionare insieme 
        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Foto Sorteggio</mat-label>
                <mat-select [(value)]="sorteggio.nomeFoto">
                    <mat-option *ngFor="let file of fileList" [value]="file.nome">
                        <b>Nome foto:</b> {{file.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->

        <mat-grid-list cols="4" rowHeight="300px">
            <mat-grid-tile [rowspan]="1">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>{{sorteggio.nomeFoto}}</mat-card-title>
                    </mat-card-header>
                    <img mat-card-image src={{fotoSorteggioUrl}} alt={{sorteggio.nomeFoto}}>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
        <br>
        <button class="btn btn-success" type="submit">Submit</button>
        <br>
    </form>
</div>
<br>
<app-footer></app-footer>
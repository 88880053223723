<div id="divCheckbox" style="display: none;">
    <input type="file" class="file-input"
    [accept]="requiredFileType"
    (change)="onFileSelected($event)"
    multiple
    #fileUpload>
</div>

<div  id="upload" class="file-upload">
    <button mat-mini-fab color="primary" class="upload-btn,loader" 
      (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon>
    </button>
   <!--<div id="loader" style="display: none;"></div>-->
</div>

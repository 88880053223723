import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DownloadMediaPojo } from 'src/app/model/DownloadMediaPojo';
import { OffertaPojo } from 'src/app/model/OffertaPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { MediaService } from 'src/app/service/media.service';
import { OfferteService } from 'src/app/service/offerte.service';

@Component({
  selector: 'app-add-offerte',
  templateUrl: './add-offerte.component.html',
  styleUrls: ['./add-offerte.component.css']
})
export class AddOfferteComponent implements OnInit {
offerta : OffertaPojo = new OffertaPojo();
res: ResponsePojo = new ResponsePojo();
fileList: DownloadMediaPojo[];
dataInvioOfferta: Date | null = null;
constructor(private service: OfferteService, private mediaService: MediaService,private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAllMedia("FOTO");
  }


  onSubmit() {
    console.log(this.offerta)
    this.addOfferta();
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    this.dataInvioOfferta = event.value;
  }

  addOfferta(): ResponsePojo {
    console.log("addSorteggio sorteggio input : " + this.offerta);
    this.offerta.dataInvio = this.dataInvioOfferta;
    this.service.addOfferta(this.offerta).subscribe((data: ResponsePojo) => {
      console.log("addOfferta : " + data);
      this.res = data;
      this.goToOfferte();
    },
      error => console.log(error));

    return this.res;
  }

  goToOfferte() {
    this.router.navigate(["offerte"]);
    if (this.res.messaggio) {
      this.openSnackBarOK(this.res.messaggio);
    } else {
      this.openSnackBarKO(this.res.error)
    }
  }


  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };


  getAllMedia(mediaType: string) {
    this.mediaService.getAllMedia("Offerta", mediaType).subscribe(data => {
      this.fileList = data;
      console.log("this.fileList MediaPanelComponent = " + this.fileList)
      err => console.log("errore : " + err)
    });
  }



}

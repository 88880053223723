import { LinguaPojo } from "./LinguaPojo";

export class GlobalVariable {
    //AWS- BE
    //AWS- BE HTTPS public static basedUrl: String = 'https://prod-sorrisotermeportal.eu-south-1.elasticbeanstalk.com';
    public static basedUrl: string =  'http://sorrisotermeportal-prod.eba-nrrcppum.eu-south-1.elasticbeanstalk.com';/*"http://localhost:8080";*/

    //UTILITY
    public static token: any;
    public static component: string;
    public static eventoNameComponent: string;
    public static lingua: LinguaPojo;
}
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ContattiPojo } from 'src/app/model/ContattiPojo';
import { ContattiValuePojo } from 'src/app/model/ContattiValuePojo';
import { GlobalVariable } from 'src/app/model/GlobalVariable';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { Testi } from 'src/app/model/Testi';
import { ContattiService } from 'src/app/service/contatti.service';
import { TestiListService } from 'src/app/service/testi-list.service';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.css']
})
export class ContattiComponent implements OnInit {
  contattiList: ContattiPojo[];
  res: ResponsePojo = new ResponsePojo();


  constructor(private service: ContattiService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAllContatti();
  }

  private getAllContatti() {
    this.service.getAllContatti().subscribe(data => {
      this.contattiList = data;
      console.log("contattiList : " + data);
    })
  }

  private deteleContatti(id) {
    this.service.deleteContattiById(id).subscribe((data: ResponsePojo) =>  {
      this.res = data;
      console.log("contattoDaCancellare : " + data);
      this.getAllContatti();
      
      if (this.res.messaggio) {
        this.openSnackBarOK(this.res.messaggio);
      } else {
        this.openSnackBarKO(this.res.error)
      }
    })
  }

  updateContatto(contatti: ContattiPojo) {
    this.router.navigate(['edit-contatti', contatti]);
  }



  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };


}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from '../model/GlobalVariable';
import { OrariPOJO } from '../model/OrariPojo';
import { Observable } from 'rxjs';
import { ResponsePojo } from '../model/ResponsePojo';

@Injectable({
  providedIn: 'root'
})
export class OrarioService {


  constructor(private http: HttpClient) { }

  tokenStr = 'Bearer ' + GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization", this.tokenStr);
  endPoint = "/portal/orari"
  orariList: OrariPOJO[];
  orariListIT: OrariPOJO[];
  orariListUK: OrariPOJO[];
  orariListDE: OrariPOJO[];

  deteleOrarioById(id: number): Observable<Object> {
    //TODO: aggiungi =>{headers}
    return this.http.delete(`${GlobalVariable.basedUrl + this.endPoint + "/deleteOrarioById"}/${id}`);
  }
  getAllOrari(): Observable<OrariPOJO[]> {
    //TODO: aggiungi =>{headers}

    const it = "it"

    return this.http.get<OrariPOJO[]>(`${GlobalVariable.basedUrl + this.endPoint + "/getAllOrari"}`);

    /* this.http.get<OrariPOJO[]>(`${GlobalVariable.basedUrl + this.endPoint + "/getAllOrariByLingua"}/${it}`,).subscribe(data => {
      console.log("URL : " + `${GlobalVariable.basedUrl + this.endPoint + "/getAllOrariByLingua"}/${it}`);
      this.orariList = data;
      console.log("orariList : " + this.orariList);
    }); */

    /*  const uk = "uk"
      this.http.get<OrariPOJO[]>(`${GlobalVariable.basedUrl + this.endPoint + "/getAllOrariByLingua"}/${uk}`,).subscribe(data => {
        this.orariListIT = data;
        console.log("testiList : " + data);
      });
  
      const de = "de"
      this.http.get<OrariPOJO[]>(`${GlobalVariable.basedUrl + this.endPoint + "/getAllOrariByLingua"}/${de}`,).subscribe(data => {
        this.orariListIT = data;
        console.log("testiList : " + data);
      });
  
      this.orariList = this.orariListIT;
      this.orariList = this.orariListUK;
      this.orariList = this.orariListDE;*/

    //return this.orariList;

  } addOrario(orario: OrariPOJO): Observable<ResponsePojo> {
    return this.http.post<ResponsePojo>(GlobalVariable.basedUrl + this.endPoint + "/addOrari", orario);
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { SorteggioCliente } from 'src/app/model/SorteggioCliente';
import { SorteggioPojo } from 'src/app/model/SorteggioPojo';
import { SorteggioService } from 'src/app/service/sorteggio.service';


@Component({
  selector: 'app-sorteggio',
  templateUrl: './sorteggio.component.html',
  styleUrls: ['./sorteggio.component.css']
})
export class SorteggioComponent implements OnInit {
sorteggioList : SorteggioPojo[];
res: ResponsePojo = new ResponsePojo();
sorc : SorteggioCliente = new SorteggioCliente();

constructor(private service: SorteggioService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }

ngOnInit(): void {
  this.getAllSorteggi();
}

private getAllSorteggi() {
  this.service.getAllSorteggi().subscribe(data => {
    this.sorteggioList = data;
    console.log("sorteggioList : " + data);
  })
}

 deteleSorteggioById(id:number) {
  console.log("sorteggio ID DaCancellare : " + id);
  this.service.deleteSorteggioById(id).subscribe((data: ResponsePojo) =>  {
    this.res = data;
    console.log("sorteggioDaCancellare : " + data);
    this.getAllSorteggi();
    
    if (this.res.messaggio) {
      this.openSnackBarOK(this.res.messaggio);
    } else {
      this.openSnackBarKO(this.res.error)
    }
  })
}

updateSorteggio(sorteggio: SorteggioPojo) {
 //this.sorc = MethodUtil.mapSorteggioTOSorteggioCliente(sorteggio);
 this.sorc.id = sorteggio.id;
 this.sorc.nomeSorteggio = sorteggio.nomeSorteggio
 this.sorc.nomeFoto = sorteggio.nomeFoto
 this.sorc.dataCreazioneSorteggio = sorteggio.dataCreazioneSorteggio
 this.sorc.sorteggioAttivo = sorteggio.sorteggioAttivo
 this.sorc.vincitoreRandom = sorteggio.vincitoreRandom
 this.sorc.idCliente = sorteggio.cliente.id
 this.sorc.nome = sorteggio.cliente.nome
 this.sorc.cognome = sorteggio.cliente.cognome
 this.sorc.mail = sorteggio.cliente.mail
 this.sorc.dataInizio = sorteggio.cliente.dataInizio
 this.sorc.dataFine = sorteggio.cliente.dataFine
  this.router.navigate(['edit-sorteggio', this.sorc ]);
}



openSnackBarOK(messaggio: string) {
  console.log("openSnackBarOK " + "messaggio : " + messaggio)
  this.snackBar.open(messaggio, null, this.configSuccess);
}

configSuccess: MatSnackBarConfig = {
  panelClass: ['snackBar-style-success'],
  duration: 2000
};


openSnackBarKO(messaggio: string) {
  this.snackBar.open(messaggio, null, this.configError);
}

configError: MatSnackBarConfig = {
  panelClass: ['snackBar-style-error'],
  duration: 2000
};


}

<app-menu></app-menu>

<div class="col-md-8 offset-md-3" style="overflow-y: scroll; height: 800px;">

    <h3> Aggiungi Orario </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Nome Orario</label>
            <input type="text" class="form-control" id="nomeOrario" [(ngModel)]="orario.nomeOrario" name="nomeOrario">
        </div>

        <div class="form-group">
            <label>Orario</label>
            <input type="text" class="form-control" id="orario" [(ngModel)]="orario.orario" name="orario">
        </div>

        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Lingua</mat-label>
                <mat-select [(value)]="orario.codiceLingua">
                    <mat-option *ngFor="let lingua of linguaList" [value]="lingua.codLingua">
                        {{lingua.nomeLingua}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <button class="btn btn-success" type="submit">Submit</button>


    </form>
</div>
<app-footer></app-footer>
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DownloadMediaPojo } from '../model/DownloadMediaPojo';
import { ResponsePojo } from '../model/ResponsePojo';
import { MediaService } from '../service/media.service';
import { MethodUtil } from '../utility/methodUtil';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configurazione',
  templateUrl: './configurazione.component.html',
  styleUrls: ['./configurazione.component.css']
})
export class ConfigurazioneComponent implements OnInit {
  fileList: DownloadMediaPojo[];
  public panelOpenState: boolean;
  res: ResponsePojo = new ResponsePojo();
  
  constructor(private mediaService: MediaService, private http: HttpClient, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
  }



  getAllMedia(component: string,mediaType: string) {
    this.mediaService.getAllMedia(component, mediaType).subscribe(data => {
      this.fileList = data;
      console.log("this.fileList MediaPanelComponent = " + this.fileList)
      err => console.log("errore : " + err)
    });
  }


  deleteMedia(component: string,nomeMedia: string, mediaType: string) {
    this.mediaService.deteleMedia(component, nomeMedia, mediaType).subscribe(data => {
      console.log("deleteMedia " + "nomeMedia : " + nomeMedia + " mediaType : " + mediaType)
      this.res = data;
      console.log("data : " + data)
      console.log("this.messaggo : " + this.res.messaggio)
      if (this.res.messaggio) {
        this.openSnackBarOK(nomeMedia);
        MethodUtil.customDelay();
      }
      err => this.openSnackBarKO(err);
      MethodUtil.customDelay();
      this.getAllMedia(component,mediaType);
    });
  }

  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio + " cancellato con successo", "", this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };
}



<app-menu></app-menu>
<h1 style="text-align: center;"> Offerte</h1>
<a routerLink="/add-offerta" routerLinkActive="active" class="nav-link">Aggiungi Offerta</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Nome Offerta</th>
            <th>Data Invio</th>
            <th>Offerta Attiva</th>
            <th>Modifica/Cancella Sorteggio</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let offerta of offerteList">
            <td>{{offerta.nomeOfferta}}</td>
            <td>{{offerta.dataInvio}}</td>
             <td>{{offerta.attiva}}</td>
            <td> 
                <button (click) = "updateOfferta(offerta)" class="btn btn-info"> Update </button>
                <button (click) = "deteleOffertaById(offerta.id)" class="btn btn-danger" style="margin-left: 10px;"> Delete </button>
            </td>   
        </tr>
    </tbody>
</table>
<app-footer></app-footer>
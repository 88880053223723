export class SorteggioCliente {
    id: number;
    nomeSorteggio: String;
    nomeFoto:String;
    dataCreazioneSorteggio: Date;
    sorteggioAttivo: boolean;
    vincitoreRandom: boolean;
    idCliente: number;
    nome: String;
    cognome: String;
    mail: String;
    numStanza: number;
    dataInizio: Date;
    dataFine: Date;
}
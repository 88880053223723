import {ClientiPojo}from "./ClientiPojo";

export class SorteggioPojo {
    id: number;
    nomeSorteggio: String;
    nomeFoto:String;
    dataCreazioneSorteggio: Date;
    sorteggioAttivo: boolean;
    vincitoreRandom: boolean;
    cliente: ClientiPojo;

}
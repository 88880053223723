import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from '../model/GlobalVariable';
import { Observable } from 'rxjs/internal/Observable';
import { NotifichePojo } from '../model/NotifichePojo';
import { ResponsePojo } from '../model/ResponsePojo';


@Injectable({
  providedIn: 'root'
})
export class NotificheService {



  constructor(private http: HttpClient) { }

  tokenStr = 'Bearer ' + GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization", this.tokenStr);
  endPoint = "/portal/notifiche"


  deleteNotificaById(id: number): Observable<ResponsePojo> {
    //TODO: aggiungi =>{headers}
    return this.http.delete<ResponsePojo>(`${GlobalVariable.basedUrl + this.endPoint + "/deleteNotificaById"}/${id}`);
  }
  getNotificheByArea(area: string): Observable<NotifichePojo[]> {
    //TODO: aggiungi =>{headers}
    return this.http.get<NotifichePojo[]>(GlobalVariable.basedUrl + this.endPoint + "/getNotificheByArea/" + area);
  }

  addNotifica(notifica: NotifichePojo): Observable<ResponsePojo> {
    return this.http.post<ResponsePojo>(GlobalVariable.basedUrl + this.endPoint + "/addNotifica", notifica);
  }

}

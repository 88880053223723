import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LinguaPojo } from 'src/app/model/LinguaPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { LinguaService } from 'src/app/service/lingua.service';
import { MethodUtil } from 'src/app/utility/methodUtil';

@Component({
  selector: 'app-edit-lingua',
  templateUrl: './edit-lingua.component.html',
  styleUrls: ['./edit-lingua.component.css']
})
export class EditLinguaComponent implements OnInit {

  lingua: LinguaPojo = new LinguaPojo();
  res : ResponsePojo = new ResponsePojo();
  constructor(private service: LinguaService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.lingua.id= this.route.snapshot.params['id']; 
    this.lingua.nomeLingua =this.route.snapshot.params['nomeLingua']; 
    this.lingua.codLingua=this.route.snapshot.params['codLingua'];
    
  }

  editLingua() : ResponsePojo{
this.res = new ResponsePojo();
     this.service.editLingua(this.lingua).subscribe(data => {
      console.log("editTesti : " + data);
     data => this.res.messaggio;
      this.goBack();
    },
    error => this.res.error) ;
    return this.res;
  }

  goBack(){
    this.router.navigate([ "/configurazione"]);
  }

  onSubmit() {
    console.log(this.lingua)
    this.editLingua();
  }


}


import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { GlobalVariable } from 'src/app/model/GlobalVariable';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { Testi } from 'src/app/model/Testi';
import { TestiListService } from 'src/app/service/testi-list.service';

@Component({
  selector: 'app-testi-list',
  templateUrl: './testi-list.component.html',
  styleUrls: ['./testi-list.component.css']
})
export class TestiListComponent implements OnInit {
  @Input() component: string;
  area: string;
  testiList: Testi[];
  //testi: Testi = new Testi();
  res: ResponsePojo = new ResponsePojo();
  constructor(private service: TestiListService, private http: HttpClient, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.res.messaggio = this.route.snapshot.params['messaggio'];
    this.res.error = this.route.snapshot.params['error'];
    this.getTestiList();
    GlobalVariable.component = this.component;
  }

  private getTestiList() {
    this.service.getTestiList(this.component).subscribe(data => {
      this.testiList = data;
      console.log("testiList : " + data);
    })
  }

  private deteleTesto(id) {
    this.service.deteleTesto(id).subscribe(data => {
      console.log("testiDaCancellare : " + data);
      this.getTestiList();
    })
  }

  updateTesto(testi: Testi) {
    GlobalVariable.lingua = testi.lingua
    this.router.navigate(['edit-testi', testi]);
    // this.router.navigate(['edit-testi', testi.lingua]);
  }

}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadMediaPojo } from 'src/app/model/DownloadMediaPojo';
import { OffertaPojo } from 'src/app/model/OffertaPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { MediaService } from 'src/app/service/media.service';
import { OfferteService } from 'src/app/service/offerte.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/public-api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit-offerte',
  templateUrl: './edit-offerte.component.html',
  styleUrls: ['./edit-offerte.component.css']
})
export class EditOfferteComponent implements OnInit {
  offerta: OffertaPojo = new OffertaPojo();
  res: ResponsePojo = new ResponsePojo();
  fileList: DownloadMediaPojo[];
  dataInvioOfferta: Date | null = null;
  dateToEdit: MatDatepickerInputEvent<Date>;
  formattedDate: string;
  fotoOffertaUrl: String;

  constructor(private service: OfferteService, private mediaService: MediaService, private router: Router, private snackBar: MatSnackBar, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getAllMedia("FOTO");
    this.offerta.id = this.route.snapshot.params['id'];
    this.offerta.nomeOfferta = this.route.snapshot.params['nomeOfferta'];

    this.offerta.dataInvio = new Date(this.route.snapshot.params['dataInvio']);
    this.formattedDate = this.offerta.dataInvio.toLocaleDateString();

    this.offerta.attiva = this.route.snapshot.params['attiva'];
    this.offerta.link = this.route.snapshot.params['link'];
    this.offerta.nomeFotoOfferta = this.route.snapshot.params['nomeFotoOfferta'];
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    this.dataInvioOfferta = event.value;
  }


  onSubmit() {
    console.log(this.offerta)
    this.editOfferta();
  }

  getAllMedia(mediaType: string) {
    this.mediaService.getAllMedia("Offerta", mediaType).subscribe(data => {
      this.fileList = data;
      console.log("this.fileList MediaPanelComponent = " + this.fileList)
      err => console.log("errore : " + err)
      this.showTheCorrectFoto(this.offerta.nomeFotoOfferta);
    });
  }


  editOfferta(): ResponsePojo {
    this.res = new ResponsePojo();
    if (this.dataInvioOfferta != null) {
      this.offerta.dataInvio = new Date(this.dataInvioOfferta);
    }


    this.service.editOfferta(this.offerta).subscribe(data => {
      console.log("editOfferta : " + data);
      this.res = data;
      this.openSnackBarOK(this.res.messaggio);
      this.goToOfferte();
    },
      error => this.res.error);
    return this.res;
  }


  private showTheCorrectFoto(nomeFoto: String) {
    if (this.fileList != null) {
      this.fileList.forEach(element => {
        if (element.nome == nomeFoto) {
          this.fotoOffertaUrl = element.url
        }
      });
    }
    console.log("fotoSorteggioUrl : " + this.fotoOffertaUrl);
  }


  goToOfferte() {
    this.router.navigate(["offerte"]);
    if (this.res.messaggio) {
      this.openSnackBarOK(this.res.messaggio);
    } else {
      this.openSnackBarKO(this.res.error)
    }
  }



  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };

}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientiPojo } from 'src/app/model/ClientiPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { SorteggioPojo } from 'src/app/model/SorteggioPojo';
import { ClientiService } from 'src/app/service/clienti.service';
import { SorteggioService } from 'src/app/service/sorteggio.service';
import { MediaService } from 'src/app/service/media.service';
import { DownloadMediaPojo } from 'src/app/model/DownloadMediaPojo';

@Component({
  selector: 'app-edit-sorteggio',
  templateUrl: './edit-sorteggio.component.html',
  styleUrls: ['./edit-sorteggio.component.css']
})
export class EditSorteggioComponent implements OnInit {
  sorteggio: SorteggioPojo = new SorteggioPojo();
  clientiList: ClientiPojo[];
  cliente: ClientiPojo = new ClientiPojo();
  client: ClientiPojo = new ClientiPojo();
  res: ResponsePojo = new ResponsePojo();
  fileList: DownloadMediaPojo[];
  public panelOpenState: boolean;
  fotoSorteggioUrl: String;
  nomeVincitore: string;
  cognomeVincitore: string;
  numStanzaVincitore: string;
  constructor(private service: SorteggioService, private mediaService: MediaService, private clientiService: ClientiService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.retriveAllClienti();
    // this.isVicitoreRandomTrue();
    this.getAllMedia("FOTO");
    this.sorteggio.id = this.route.snapshot.params['id'];
    this.sorteggio.nomeSorteggio = this.route.snapshot.params['nomeSorteggio'];
    this.sorteggio.sorteggioAttivo = this.route.snapshot.params['sorteggioAttivo'];
    this.sorteggio.vincitoreRandom = this.route.snapshot.params['vincitoreRandom'];
    this.sorteggio.nomeFoto = this.route.snapshot.params['nomeFoto'];
    this.cliente.id = this.route.snapshot.params['idCliente'];
    this.cliente.nome = this.route.snapshot.params['nome'];
    this.cliente.cognome = this.route.snapshot.params['cognome'];
    this.cliente.mail = this.route.snapshot.params['mail'];
    this.cliente.dataInizio = this.route.snapshot.params['dataInizio'];
    this.cliente.dataFine = this.route.snapshot.params['dataFine'];
    this.sorteggio.cliente = this.cliente;


    this.nomeVincitore = this.route.snapshot.params['nome'];
    this.cognomeVincitore = this.route.snapshot.params['cognome'];
    this.numStanzaVincitore = this.route.snapshot.params['numStanza'];



    console.log("this.sorteggio.vincitoreRandom : " + this.sorteggio.vincitoreRandom);
    console.log("JSON.stringify(this.sorteggio.vincitoreRandom) : " + JSON.stringify(this.sorteggio.vincitoreRandom));
    if (JSON.stringify(this.sorteggio.vincitoreRandom) == "\"false\"") {
      document.getElementById("vicitoreRandomCheck").style.display = "none";
    }
  }

  editSorteggio(): ResponsePojo {
    this.res = new ResponsePojo();
    this.service.editSorteggio(this.sorteggio).subscribe(data => {
      console.log("editSorteggio : " + data);
      data => this.res.messaggio;
      this.goToSorteggio();
    },
      error => this.res.error);
    return this.res;
  }

  getAllMedia(mediaType: string) {
    this.mediaService.getAllMedia("Sorteggio", mediaType).subscribe(data => {
      this.fileList = data;
      console.log("this.fileList MediaPanelComponent = " + this.fileList)
      err => console.log("errore : " + err)
      this.showTheCorrectFoto(this.sorteggio.nomeFoto);
    });
  }

  private showTheCorrectFoto(nomeFoto: String) {
    if (this.fileList != null) {
      this.fileList.forEach(element => {
        if (element.nome == nomeFoto) {
          this.fotoSorteggioUrl = element.url
        }
      });
    }
    console.log("fotoSorteggioUrl : " + this.fotoSorteggioUrl);
  }


  goToSorteggio() {
    this.router.navigate(['/sorteggio']);
  }

  onSubmit() {
    console.log(this.sorteggio)
    this.editSorteggio();
  }

  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };

  retriveAllClienti() {
    this.clientiService.getAllClientiAvailable().subscribe(data => {
      this.clientiList = data;
      console.log("clientiList : " + data);
      if (this.clientiList.length == 0) {
        document.getElementById("vicitoreRandom").style.display = "none";
        document.getElementById("clienteNotPresent").style.display = "inline";
      }
    })
  }


  // isVicitoreRandomTrue() {
  //   let isVR = localStorage.getItem('vicitoreRandomStr');
  //   if (isVR == "none") {
  //     document.getElementById("vicitoreRandom").style.display = "inline";
  //     //localStorage.setItem('vicitoreRandomStr', "none");
  //   } else {
  //     document.getElementById("vicitoreRandom").style.display = "none";
  //     //localStorage.setItem('vicitoreRandomStr', "none");
  //   }

  // }

}

<app-menu></app-menu>
<h1 style="text-align: center;"> Noleggio</h1>
<h2 style="text-align: center;"> Upload file</h2>
<mat-accordion>
    <mat-expansion-panel (opened)="getAllMedia('FOTO')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui le foto
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon>photo_camera</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType="image/*" fileType="FOTO" frase="Nessuna foto selezionata"
            component="Noleggio"></app-upload-file>
        <br>
        <div class="cdk-virtual-scroll-data-source-example">
            <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="50" class="example-viewport">

                <div *cdkVirtualFor="let file of fileList" class="example-item">

                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{file.nome}}</mat-card-title>
                        </mat-card-header>
                        <img mat-card-image src={{file.url}} alt={{file.nome}}>
                        <mat-card-actions>
                            <button mat-button (click)="deleteMedia(file.nome,'FOTO')">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<br>
<app-testi-list component="Noleggio"></app-testi-list>
<br>
<app-notifiche component="Noleggio"></app-notifiche>
<br>
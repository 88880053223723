<app-menu></app-menu>

<div class="col-md-8 offset-md-3" style="height: 1000px;overflow-x: hidden;">
<br>
    <h3> Modifica Offerta </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Nome Offerta</label>
            <input type="text" class="form-control" id="nomeOfferta" [(ngModel)]="offerta.nomeOfferta" name="nomeOfferta">
        </div>
        <div class="form-group">
            <label>Attiva Offerta</label>
            <input type="checkbox" class="form-control" id="attiva" name="attiva" [(ngModel)]="offerta.attiva"  checked="checked">
        </div>

        <div class="form-group">
            <label>Data di invio attuale</label>
            <input type="text" class="form-control" id="dataInvio" [(ngModel)]="formattedDate" name="dataInvio" disabled>
        </div>
        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Modifica data di invio</mat-label>
                <input matInput [matDatepicker]="picker" (dateInput)="setDate($event)">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="form-group">
            <label>Link Offerta sul sito</label>
            <input type="text" class="form-control" id="link" name="link" [(ngModel)]="offerta.link"  >
        </div>
        <mat-grid-list cols="4" rowHeight="300px">
            <mat-grid-tile [rowspan]="1">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>{{offerta.nomeFotoOfferta}}</mat-card-title>
                    </mat-card-header>
                    <img mat-card-image src={{fotoOffertaUrl}} alt={{offerta.nomeFotoOfferta}}>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>  
       <button class="btn btn-success" type="submit">Submit</button>
    </form>
</div>
<br>
<app-footer></app-footer>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalVariable } from '../model/GlobalVariable';
@Injectable({
  providedIn: 'root'
})
export class JwtClientService {
  constructor(private http : HttpClient) { }
  token: any



  public genereteToken(request: any){
  console.log("request : " + request);
    let generatedToken =  this.http.post(GlobalVariable.basedUrl +"/login", request,{responseType: 'text' as 'json'});
  
     generatedToken.subscribe(data=> {
      console.log("Token JW: "+data);
      GlobalVariable.token = data;
     });

  
    return generatedToken;
  }
  
  
  public home(){
  let tokenStr = 'Bearer ' +  GlobalVariable.token;
  const headers = new HttpHeaders().set("Authorization",tokenStr);
  return this.http.get(GlobalVariable.basedUrl +"/home/welcome", {responseType: 'text' as 'json' });
  }
}

<h2 style="text-align: center;"> Notifiche</h2>
<!--<div class="form-group">
    <label style="color: red;text-align: center;">!!! ATTENZIONE !!! - La sezione delle notifiche è al momento solo grafica in quanto necessito dell'app per completarla. È stata aggiunta solo per avere una visione grafica</label>
    <br>
    <label>Aggiungi testo notifica</label>
 <input type="text" class="form-control" id="area" name="area"> 
</div> -->


<a routerLink="/add-notifica" routerLinkActive="active" class="nav-link">Aggiungi Notifiche Locali</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Area</th>
            <th>Testo</th>
            <th>Codice Lingua</th>
            <th>Ora Notifica</th>
            <th>Minuto Notifica</th>
            <th>Ripeti Notifica</th>
            <th>Giorno Settimana</th>
            <th>Data Notifica</th>
            <th>Cancella Notifica</th>
        </tr>
    </thead>
    <tbody>
       <tr *ngFor="let notifica of notificheList">
            <td>{{notifica.area}}</td>
            <td>{{notifica.testo}}</td>
            <td>{{notifica.codLingua}}</td>
            <td>{{notifica.oraNotifica}}</td>
            <td>{{notifica.minutiNotifica}}</td>
            <td>{{notifica.repeats}}</td>
            <td>{{notifica.giornoSettimana}}</td>
            <td>{{notifica.dataNotifica}}</td>
            <td>
                <button (click) = "deleteNotificaById(notifica.id)" class="btn btn-danger" style="margin-left: 10px;"> Delete </button>
            </td>   
        </tr>
    </tbody>
</table>

<app-menu></app-menu>
<h1 style="text-align: center;"> Eventi</h1>
<a routerLink="/add-eventi" routerLinkActive="active" class="nav-link">Aggiungi Evento</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Nome</th>
            <th>Data Evento</th>
            <th>Ora</th>
            <th>Luogo</th>
            <th>Modifica/Cancella Testo</th>
        </tr>
    </thead>
    <tbody>
         <tr *ngFor="let evento of eventiList">
            <td>{{evento.nome}}</td>
            <td>{{evento.dataEvento}}</td>
            <td>{{evento.ora}}</td>
            <td>{{evento.luogo}}</td>
            
             <td> 
                <button (click) = "updateEvento(evento)" class="btn btn-info"> Update </button>
                <button (click) = "deteleEvento(evento)" class="btn btn-danger" style="margin-left: 10px;"> Delete </button>
            </td>   
        </tr>
     </tbody>
</table>
<app-footer></app-footer>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BluBarComponent } from './blu-bar/blu-bar.component';
//import { CardMediaComponent } from './card-media/card-media.component';
import { HomeComponent } from './home/home.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { MappeComponent } from './mappe/mappe.component';
import { PiscineComponent } from './piscine/piscine.component';
import { PoolBarComponent } from './pool-bar/pool-bar.component';
import { RistoranteOasisComponent } from './ristorante-oasis/ristorante-oasis.component';
import { SpaComponent } from './spa/spa.component';
import { AddTestiComponent } from './testi/add-testi/add-testi.component';
import { EditTestiComponent } from './testi/edit-testi/edit-testi.component';
import { TestiListComponent } from './testi/testi-list/testi-list.component';
import { RistoranteHotelComponent } from './ristorante-hotel/ristorante-hotel.component';
import { NavettaComponent } from './navetta/navetta.component';
import { EscursioniComponent } from './escursioni/escursioni.component';
import { NoleggioComponent } from './noleggio/noleggio.component';
import { EventiComponent } from './Events/eventi/eventi.component';
import { ContattiComponent } from './Contact/contatti/contatti.component';
import { AddContattiComponent } from './Contact/add-contatti/add-contatti.component';
import { EditContattiComponent } from './Contact/edit-contatti/edit-contatti.component';
import { ConfigurazioneComponent } from './configurazione/configurazione.component';
import { LinguaComponent } from './Language/lingua/lingua.component';
import { AddLinguaComponent } from './Language/add-lingua/add-lingua.component';
import { EditLinguaComponent } from './Language/edit-lingua/edit-lingua.component';
import { AddEventiComponent } from './Events/add-eventi/add-eventi.component';
import { EditEventiComponent } from './Events/edit-eventi/edit-eventi.component';
import { SorteggioComponent } from './SorteggioF/sorteggio/sorteggio.component';
import { AddSorteggioComponent } from './SorteggioF/add-sorteggio/add-sorteggio.component';
import { EditSorteggioComponent } from './SorteggioF/edit-sorteggio/edit-sorteggio.component';
import { OfferteListComponent } from './Offerte/offerte-list/offerte-list.component';
import { AddOfferteComponent } from './Offerte/add-offerte/add-offerte.component';
import { EditOfferteComponent } from './Offerte/edit-offerte/edit-offerte.component';
import { OrariComponent } from './Oraris/orari/orari.component';
import { AddOrarioComponent } from './Oraris/add-orario/add-orario.component';
import { AddNotificheComponent } from './NotificheFolder/add-notifiche/add-notifiche.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "login", component: LoginComponentComponent },
  { path: "home", component: HomeComponent },
  { path: "ristoranteOasis", component: RistoranteOasisComponent },
  { path: "testi-list", component: TestiListComponent },
  { path: "add-testi", component: AddTestiComponent },
  { path: "edit-testi", component: EditTestiComponent },
  // { path: "card-media", component: CardMediaComponent },
  { path: "spa", component: SpaComponent },
  { path: "piscine", component: PiscineComponent },
  { path: "pool-bar", component: PoolBarComponent },
  { path: "blu-bar", component: BluBarComponent },
  { path: "mappe", component: MappeComponent },
  { path: "ristoranteHotel", component: RistoranteHotelComponent },
  { path: "navetta", component: NavettaComponent },
  { path: "escursioni", component: EscursioniComponent },
  { path: "noleggio", component: NoleggioComponent },
  { path: "eventi", component: EventiComponent },
  { path: "add-eventi", component: AddEventiComponent },
  { path: "edit-eventi", component: EditEventiComponent },
  { path: "contatti", component: ContattiComponent },
  { path: "add-contatti", component: AddContattiComponent },
  { path: "edit-contatti", component: EditContattiComponent },
  { path: "configurazione", component: ConfigurazioneComponent },
  { path: "lingua", component: LinguaComponent },
  { path: "add-lingua", component: AddLinguaComponent },
  { path: "edit-lingua", component: EditLinguaComponent },
  { path: "sorteggio", component: SorteggioComponent },
  { path: "add-sorteggio", component: AddSorteggioComponent },
  { path: "edit-sorteggio", component: EditSorteggioComponent },
  { path: "offerte", component: OfferteListComponent },
  { path: "add-offerta", component: AddOfferteComponent },
  { path: "edit-offerta", component: EditOfferteComponent },
  { path: "orari", component: OrariComponent },
  { path: "add-orari", component: AddOrarioComponent },
  { path: "add-notifica", component: AddNotificheComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

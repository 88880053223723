import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { LoginServiceService } from './service/login-service.service';
import { RistoranteOasisComponent } from './ristorante-oasis/ristorante-oasis.component';
import { TestiListComponent } from './testi/testi-list/testi-list.component';
import { AddTestiComponent } from './testi/add-testi/add-testi.component';
import { EditTestiComponent } from './testi/edit-testi/edit-testi.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { UploadFileComponent } from './upload-file/upload-file.component';
//import { CardMediaComponent } from './card-media/card-media.component';
import { MediaPanelComponent } from './media-panel/media-panel.component';
import { NotificheComponent } from './NotificheFolder/notifiche/notifiche.component';
import { SpaComponent } from './spa/spa.component';
import { MediaPanelTwoMediaOnePdfComponent } from './media-panel-two-media-one-pdf/media-panel-two-media-one-pdf.component';
import { PiscineComponent } from './piscine/piscine.component';
import { MediaPanelTwoMediaComponent } from './media-panel-two-media/media-panel-two-media.component';
import { PoolBarComponent } from './pool-bar/pool-bar.component';
import { BluBarComponent } from './blu-bar/blu-bar.component';
import { MappeComponent } from './mappe/mappe.component';
import { RistoranteHotelComponent } from './ristorante-hotel/ristorante-hotel.component';
import { NavettaComponent } from './navetta/navetta.component';
import { EscursioniComponent } from './escursioni/escursioni.component';
import { NoleggioComponent } from './noleggio/noleggio.component';
import { EventiComponent } from './Events/eventi/eventi.component';
import { ContattiComponent } from './Contact/contatti/contatti.component';
import { AddContattiComponent } from './Contact/add-contatti/add-contatti.component';
import { EditContattiComponent } from './Contact/edit-contatti/edit-contatti.component';
import { MatSelectModule } from '@angular/material/select';
import { ConfigurazioneComponent } from './configurazione/configurazione.component';
import { LinguaComponent } from './Language/lingua/lingua.component';
import { AddLinguaComponent } from './Language/add-lingua/add-lingua.component';
import { EditLinguaComponent } from './Language/edit-lingua/edit-lingua.component';
import { AddEventiComponent } from './Events/add-eventi/add-eventi.component';
import { EditEventiComponent } from './Events/edit-eventi/edit-eventi.component';
import { ExampleHeaderComponent } from './example-header/example-header.component';
import { AddTestiPopUpComponent } from './add-testi-pop-up/add-testi-pop-up.component';
import { EditTestiPopUpComponent } from './edit-testi-pop-up/edit-testi-pop-up.component';
import { SorteggioComponent } from './SorteggioF/sorteggio/sorteggio.component';
import { AddSorteggioComponent } from './SorteggioF/add-sorteggio/add-sorteggio.component';
import { EditSorteggioComponent } from './SorteggioF/edit-sorteggio/edit-sorteggio.component';
import { OfferteListComponent } from './Offerte/offerte-list/offerte-list.component';
import { AddOfferteComponent } from './Offerte/add-offerte/add-offerte.component';
import { EditOfferteComponent } from './Offerte/edit-offerte/edit-offerte.component';
import { OrariComponent } from './Oraris/orari/orari.component';
import { AddOrarioComponent } from './Oraris/add-orario/add-orario.component';
import { AddNotificheComponent } from './NotificheFolder/add-notifiche/add-notifiche.component';
import { RouterModule } from '@angular/router';
import {ChangeDetectionStrategy} from '@angular/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponentComponent,
    HomeComponent,
    FooterComponent,
    MenuComponent,
    RistoranteOasisComponent,
    TestiListComponent,
    AddTestiComponent,
    EditTestiComponent,
    UploadFileComponent,
    // CardMediaComponent,
    MediaPanelComponent,
    NotificheComponent,
    SpaComponent,
    MediaPanelTwoMediaOnePdfComponent,
    PiscineComponent,
    MediaPanelTwoMediaComponent,
    PoolBarComponent,
    BluBarComponent,
    MappeComponent,
    RistoranteHotelComponent,
    NavettaComponent,
    EscursioniComponent,
    NoleggioComponent,
    EventiComponent,
    ContattiComponent,
    AddContattiComponent,
    EditContattiComponent,
    ConfigurazioneComponent,
    LinguaComponent,
    AddLinguaComponent,
    EditLinguaComponent,
    AddEventiComponent,
    EditEventiComponent,
    ExampleHeaderComponent,
    AddTestiPopUpComponent,
    EditTestiPopUpComponent,
    SorteggioComponent,
    AddSorteggioComponent,
    EditSorteggioComponent,
    OfferteListComponent,
    AddOfferteComponent,
    EditOfferteComponent,
    OrariComponent,
    AddOrarioComponent,
    AddNotificheComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ], 
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
    MaterialModule,
    MatSelectModule,ScrollingModule,MatDividerModule,MatListModule
  ],
  providers: [LoginServiceService,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }

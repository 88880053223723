<h2 style="text-align: center;"> Upload file</h2>
<mat-accordion>
    <mat-expansion-panel (opened)="getAllMedia('FOTO')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui le foto
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon>photo_camera</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType="image/*" fileType="FOTO" frase="Nessuna foto selezionata"
            component={{component}}></app-upload-file>
        <br>

        <!-- <mat-grid-list cols="4" rowHeight="300px" class="grid-container">
            <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1"> -->

        <div class="cdk-virtual-scroll-data-source-example">
            <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="50" class="example-viewport">

                <div *cdkVirtualFor="let file of fileList" class="example-item">

                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{file.nome}}</mat-card-title>
                        </mat-card-header>
                        <img mat-card-image src={{file.url}} alt={{file.nome}}>
                        <mat-card-actions>
                            <button mat-button (click)="deleteMedia(file.nome,'FOTO')">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-card-actions>
                    </mat-card>
                    <br>
                </div>

            </cdk-virtual-scroll-viewport>
        </div>


        <!--  </mat-grid-tile>
        </mat-grid-list> -->
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="getAllMedia('VIDEO')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui i video
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon>videocam</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType="video/*" fileType="VIDEO" frase="Nessun video selezionato"
            component={{component}}></app-upload-file>
        <br>
        <mat-grid-list cols="4" rowHeight="600px">
            <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>{{file.nome}}</mat-card-title>
                    </mat-card-header>
                    <video width="250" controls>
                        <source src={{file.url}}>
                    </video>
                    <mat-card-actions>
                        <button mat-button (click)="deleteMedia(file.nome,'VIDEO').click">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-expansion-panel>
</mat-accordion>
<nav class="navbar navbar-expand-sm " style="background-color: #abc1e6;">
    <ul class="navbar-nav">
        <button mat-button class="nav-link" style="color: #365c9c;" routerLink="/home">Home</button>

        <button mat-button [matMenuTriggerFor]="ristoranti" class="nav-link" style="color: #365c9c;">Ristoranti</button>
        <mat-menu #ristoranti="matMenu">
            <button mat-menu-item routerLink="/ristoranteOasis" class="nav-link"
                style="color: rgb(54, 92, 156);">RistoranteOasis</button>
            <button mat-menu-item routerLink="/ristoranteHotel" class="nav-link"
                style="color: rgb(54, 92, 156);">RistoranteHotel</button>
        </mat-menu>

        <button mat-button [matMenuTriggerFor]="bar" class="nav-link" style="color: #365c9c;">Bar</button>
        <mat-menu #bar="matMenu">
            <button mat-menu-item routerLink="/blu-bar" class="nav-link" style="color: #365c9c;"> BluBar</button>
            <button mat-menu-item routerLink="/pool-bar" class="nav-link" style="color: #365c9c;"> PoolBar</button>
        </mat-menu>

        <button mat-button routerLink="/spa" class="nav-link" style="color: #365c9c;">SPA</button>
        <button mat-button routerLink="/piscine" class="nav-link" style="color: #365c9c;">Piscine</button>
        <button mat-button routerLink="/mappe" class="nav-link" style="color: #365c9c;">Mappe</button>
        <button mat-button routerLink="/navetta" class="nav-link" style="color: #365c9c;">Navetta</button>
        <button mat-button routerLink="/escursioni" class="nav-link" style="color: #365c9c;">Escursioni</button>
        <button mat-button routerLink="/noleggio" class="nav-link" style="color: #365c9c;">Noleggio</button>
        <button mat-button routerLink="/eventi" class="nav-link" style="color: #365c9c;">Eventi</button>
        <button mat-button routerLink="/contatti" class="nav-link" style="color: #365c9c;">Contatti</button>
        <button mat-button routerLink="/sorteggio" class="nav-link" style="color: #365c9c;">Sorteggio</button>
        <button mat-button routerLink="/offerte" class="nav-link" style="color: #365c9c;">Offerte</button>
        <button mat-button routerLink="/orari" class="nav-link" style="color: #365c9c;">Orari</button>
        <button mat-button routerLink="/configurazione" class="nav-link" style="color: #365c9c;">Configurazione</button>
         <!-- <button mat-button routerLink="/login" class="nav-link" style="color: #365c9c;">Login</button> -->

    </ul>
</nav>
import { Component, Input, OnInit } from '@angular/core';
import { NotifichePojo } from '../../model/NotifichePojo';
import { ResponsePojo } from '../../model/ResponsePojo';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificheService } from '../../service/notifiche.service';
import { GlobalVariable } from 'src/app/model/GlobalVariable';

@Component({
  selector: 'app-notifiche',
  templateUrl: './notifiche.component.html',
  styleUrls: ['./notifiche.component.css']
})
export class NotificheComponent implements OnInit {
  @Input() component: string;
  notificheList: NotifichePojo[];
  notifica: NotifichePojo;
  res: ResponsePojo = new ResponsePojo();
  constructor(private service: NotificheService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }


  ngOnInit(): void {
    GlobalVariable.component = this.component;
    this.getNotificheByArea(this.component);
  }

  private getNotificheByArea(area) {
    this.service.getNotificheByArea(area).subscribe(data => {
      this.notificheList = data;
      console.log("contattiList : " + data);
    })
  }

  private deleteNotificaById(id) {
    this.service.deleteNotificaById(id).subscribe((data: ResponsePojo) => {
      this.res = data;
      console.log("notificaDaCancellare : " + JSON.stringify(data));
      this.getNotificheByArea(this.component);

      if (this.res.messaggio) {
        this.openSnackBarOK(this.res.messaggio);
      } else {
        this.openSnackBarKO(this.res.error)
      }
    })
  }





  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };
}

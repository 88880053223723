
<h2 style="text-align: center;"> Testi</h2>
<!--<button class="btn btn-info"> <a routerLink="/add-testi"  class="nav-link"> Aggiungi Testi</a></button>-->
<!-- <div class="mat-dialog-container"  *ngIf="res.messaggio!= null" >
  <div class="col-xs-12" >
      <p class="alert alert-success">
          <strong>{{res.messaggio}}</strong> 
      </p>
    </div>
  </div>

  <div class="mat-dialog-container"  *ngIf="res.error!= null">
    <div class="col-xs-12">
      <p class="alert alert-danger">
          <strong>{{res.error}}</strong> 
      </p>
    </div>
  </div> -->

<a routerLink="/add-testi" routerLinkActive="active" class="nav-link">Aggiungi Testi</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Testo</th>
            <th>Lingua</th>
            <th>Modifica/Cancella Testo</th>
        </tr>
    </thead>
    <tbody>
         <tr *ngFor="let testi of testiList">
            <td>{{testi.testo}}</td>
            <td>{{testi.nomeLingua}}</td>
            <td> 
                <button (click) = "updateTesto(testi)" class="btn btn-info"> Update </button>
                <button (click) = "deteleTesto(testi.id)" class="btn btn-danger" style="margin-left: 10px;"> Delete </button>
            </td>  
        </tr>
     </tbody>
</table>
<app-footer></app-footer>



<app-menu></app-menu>

<div class="col-md-8 offset-md-3" style="overflow-y: scroll; height: 800px;">

    <h3> Modifica Lingua </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Nome Lingua</label>
            <input type="text" class="form-control" id="nomelingua" [(ngModel)]="lingua.nomeLingua" name="nomelingua">
        </div>

        <div class="form-group">
            <label>Codice Lingua</label>
            <input type="text" class="form-control" id="codLingua" [(ngModel)]="lingua.codLingua" name="codLingua">
        </div>

        <button class="btn btn-success" type="submit">Submit</button>
    </form>
</div>
<app-footer></app-footer>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceService } from '../service/login-service.service';
import {NgForm} from '@angular/forms';
import { User } from '../model/User';
import { JwtClientService } from '../service/jwt-client.service';
import { ResponsePojo } from '../model/ResponsePojo';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MethodUtil } from '../utility/methodUtil';
import { LoginPojo } from '../model/LoginPojo';


@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.css']
})
export class LoginComponentComponent implements OnInit {
  user: LoginPojo = new LoginPojo();
  res: ResponsePojo = new ResponsePojo();
  constructor(private service : JwtClientService,private loginService : LoginServiceService, private router : Router,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  doLogin() {
    console.log("UserName = " + this.user.username)
    console.log("Password = " + this.user.password)
       /* const request = {
      "username":this.user.userName,
      "password": this.user.password
  };
    let resp = this.service.genereteToken(request).subscribe(data => {
      console.log(data);
      this.goToHome();
    },
     error => console.log(error));*/

this.loginService.login(this.user).subscribe(data => {

  this.res = data;
  console.log("data : " + data)
  console.log("this.messaggo : " + this.res.messaggio)
  if (this.res.messaggio) {
    this.goToHome();
    this.openSnackBarOK(this.res.messaggio);
    MethodUtil.customDelay();
  }
  err => this.openSnackBarKO(err);

})

    
  }



  goToHome() {
    this.router.navigate(['/home']);
    console.log("ANDIAMO IN Home!")
  }



  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio + " cancellato con successo", "", this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };

}

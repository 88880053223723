import { LinguaPojo } from "./LinguaPojo";


export class Testi {
	id: number;
	area: String;
	testo: String;
	nomeLingua: String;
	codLingua: String;
	lingua: LinguaPojo;
	eventoId: String;
}
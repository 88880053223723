import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsePojo } from '../model/ResponsePojo';

@Component({
  selector: 'app-ristorante-oasis',
  templateUrl: './ristorante-oasis.component.html',
  styleUrls: ['./ristorante-oasis.component.css']
})
export class RistoranteOasisComponent implements OnInit {
 
  constructor() { }

  ngOnInit(): void {
    
  }

}

<app-menu></app-menu>
<br>
<h1 style="text-align: center;">{{welcomeStr}}</h1>
<mat-divider></mat-divider>
<br>
<h1 style="text-align: left;">Bug</h1>
<p style="color: red;">Apri il file di sotto, troverari un Excel con due sheet, <strong>Bug Portale e Bug App</strong>, dove potrai
    aggiungere tutti i bug.</p>
<p style="color: red;">Nel file, nella colonna <strong>"Link Foto drive"</strong>, clicca su <strong>"Link Foto drive"</strong>, verrai
    reindirizzato sul google drive.</p>
<p style="color: red;">Li, sotto le cartelle, <strong>"Foto Bug Portale/App"</strong> potrai creare una cartella con l'id del bug, dove aggiungerai i
    relativi screen.</p>
    <br>
<a mat-raised-button
    href="https://docs.google.com/spreadsheets/d/1OW0iLWjhhcM9t_unD7fo403AylnOe1wfesZXW7A7oB8/edit?usp=sharing"
    target="_blank">Apri il file dei bug</a>
<app-footer></app-footer>
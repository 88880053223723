<app-menu></app-menu>
<h2 style="text-align: center;"> Contatti</h2>
<a routerLink="/add-contatti" routerLinkActive="active" class="nav-link">Aggiungi Contatti</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Nome Contatto</th>
            <th>Tipologia Contatto</th>
            <th>Valore Contatto</th>
            <th>Modifica/Cancella Contatto</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let contatto of contattiList">
            <td>{{contatto.nomeContatto}}</td>
            <td>{{contatto.tipologiaContatto}}</td>
             <td>{{contatto.valoreContatto}}</td>
            <td> 
                <button (click) = "updateContatto(contatto)" class="btn btn-info"> Update </button>
                <button (click) = "deteleContatti(contatto.id)" class="btn btn-danger" style="margin-left: 10px;"> Delete </button>
            </td>   
        </tr>
    </tbody>
</table>
<app-footer></app-footer>
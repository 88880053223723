<app-menu></app-menu>

<div class="col-md-8 offset-md-3" style="overflow-y: scroll; height: 800px;">

    <h3> Aggiungi Notifica </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Area</label>
            <input type="text" class="form-control" id="area" [(ngModel)]="notifica.area" name="area">
        </div>

        <div class="form-group">
            <label>Testo</label>
            <input type="text" class="form-control" id="testo" [(ngModel)]="notifica.testo" name="testo">
        </div>

        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Lingua</mat-label>
                <mat-select [(value)]="notifica.codLingua">
                    <mat-option *ngFor="let lingua of linguaList" [value]="lingua.codLingua">
                        {{lingua.nomeLingua}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group">
            <label>Ora Notifica</label>
            <input type="text" class="form-control" id="oraNotifica" [(ngModel)]="notifica.oraNotifica"
                name="oraNotifica">
        </div>

        <div class="form-group">
            <label>Minuto Notifica</label>
            <input type="text" class="form-control" id="minutiNotifica" [(ngModel)]="notifica.minutiNotifica"
                name="minutiNotifica">
        </div>

        <div class="form-group">
            <label> Ripeti Notifica </label>
            <input type="checkbox" class="form-control" id="repeats" name="repeats" [(ngModel)]="notifica.repeats"
                checked="checked">
        </div>


        <div class="form-group">
            <label>Giorno Settimana</label>
            <input type="text" class="form-control" id="orario" [(ngModel)]="notifica.giornoSettimana" name="orario">
        </div>

        <div class="form-group">
            <label>Data Notifica</label>
            <input type="text" class="form-control" id="orario" [(ngModel)]="notifica.dataNotifica" name="orario">
        </div>


        <button class="btn btn-success" type="submit">Submit</button>


    </form>
</div>
<app-footer></app-footer>
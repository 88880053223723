import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ristorante-hotel',
  templateUrl: './ristorante-hotel.component.html',
  styleUrls: ['./ristorante-hotel.component.css']
})
export class RistoranteHotelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalVariable } from '../model/GlobalVariable';
import { ResponsePojo } from '../model/ResponsePojo';
import { switchMap, map, tap } from 'rxjs/operators';
import { DownloadMediaPojo } from '../model/DownloadMediaPojo';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) { }

  tokenStr = 'Bearer ' + GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization", this.tokenStr);
  endPoint = "/portal/media"

  addMedia(formData: FormData, component: String, mediaType: String): Observable<ResponsePojo> {
    //TODO: aggiungi =>{headers}
    return this.http.post<ResponsePojo>(GlobalVariable.basedUrl + this.endPoint + "/addMedia/" + component + "/" + mediaType, formData);
  }


  getAllMedia(component: String, mediaType: String): Observable<DownloadMediaPojo[]> {
    //TODO: aggiungi =>{headers}
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<DownloadMediaPojo[]>(GlobalVariable.basedUrl + this.endPoint + "/downloadAllMedia/" + component + "/" + mediaType, httpOptions);
  }



  private readFile(blob: Blob): Observable<string[]> {
    return Observable.create(obs => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }


  deteleMedia(component: String, nomeMedia: String, mediaType: String): Observable<ResponsePojo> {
    //TODO: aggiungi =>{headers}
    return this.http.delete<ResponsePojo>(`${GlobalVariable.basedUrl + this.endPoint + "/deleteMediaByName"}/${component}/${mediaType}/${nomeMedia}`);
  }


  deteleMediaByEventoId(component: String, eventoId: string): Observable<ResponsePojo> {
    //TODO: aggiungi =>{headers}
    return this.http.delete<ResponsePojo>(`${GlobalVariable.basedUrl + this.endPoint + "/deleteMediaByEventoId"}/${component}/${eventoId}`);
  }


}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ContattiPojo } from 'src/app/model/ContattiPojo';
import { ContattiValuePojo } from 'src/app/model/ContattiValuePojo';
import { GlobalVariable } from 'src/app/model/GlobalVariable';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { ContattiService } from 'src/app/service/contatti.service';
import { TestiListService } from 'src/app/service/testi-list.service';
import { MethodUtil } from 'src/app/utility/methodUtil';
import {MatSelectModule} from '@angular/material/select';
@Component({
  selector: 'app-add-contatti',
  templateUrl: './add-contatti.component.html',
  styleUrls: ['./add-contatti.component.css']
})
export class AddContattiComponent implements OnInit {
  contatto: ContattiPojo = new ContattiPojo();
  res: ResponsePojo = new ResponsePojo();
  contattiValueList : ContattiValuePojo[] = [
    {value: 'email', viewValue: 'Email'},
    {value: 'telefono', viewValue: 'Telefono'},
    {value: 'cellulare', viewValue: 'Cellulare'},
    {value: 'sito', viewValue: 'Sito'}
  ];
  contattiValue : ContattiValuePojo = new ContattiValuePojo();
  constructor(private service: ContattiService, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
      
  }

  addTesti(): ResponsePojo {
    this.service.addContatti(this.contatto).subscribe((data: ResponsePojo) => {
      console.log("addContatti : " + data);
      this.res = data;
      this.goToContatti();
    },
      error => console.log(error));

    return this.res;
  }

  onSubmit() {
    console.log(this.contatto)
    this.addTesti();
  }

  goToContatti() {
    this.router.navigate([ "contatti"]);
    if (this.res.messaggio) {
      this.openSnackBarOK(this.res.messaggio);
    } else {
      this.openSnackBarKO(this.res.error)
    }
  }

  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };

}

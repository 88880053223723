import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ClientiPojo } from 'src/app/model/ClientiPojo';
import { DownloadMediaPojo } from 'src/app/model/DownloadMediaPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { SorteggioPojo } from 'src/app/model/SorteggioPojo';
import { ClientiService } from 'src/app/service/clienti.service';
import { MediaService } from 'src/app/service/media.service';
import { SorteggioService } from 'src/app/service/sorteggio.service';


@Component({
  selector: 'app-add-sorteggio',
  templateUrl: './add-sorteggio.component.html',
  styleUrls: ['./add-sorteggio.component.css']
})
export class AddSorteggioComponent implements OnInit {
  sorteggio: SorteggioPojo = new SorteggioPojo();
  clientiList: ClientiPojo[];
  res: ResponsePojo = new ResponsePojo();
  fileList: DownloadMediaPojo[];
  public panelOpenState: boolean;

  constructor(private service: SorteggioService, private mediaService: MediaService, private clientiService: ClientiService, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    document.getElementById("vicitoreRandom").style.display = "inline";
      this.getAllMedia("FOTO");
     this.retriveAllClienti();
  }

  addSorteggio(): ResponsePojo {
    console.log("addSorteggio sorteggio input : " + this.sorteggio);
    this.service.addSorteggio(this.sorteggio).subscribe((data: ResponsePojo) => {
      console.log("addSorteggio : " + data);
      this.res = data;
      this.goToSorteggi();
    },
      error => console.log(error));

    return this.res;
  }

  onSubmit() {
    console.log(this.sorteggio)
    this.addSorteggio();
  }

  goToSorteggi() {
    this.router.navigate(["sorteggio"]);
    if (this.res.messaggio) {
      this.openSnackBarOK(this.res.messaggio);
    } else {
      this.openSnackBarKO(this.res.error)
    }
  }

  getAllMedia(mediaType: string) {
    this.mediaService.getAllMedia("Sorteggio", mediaType).subscribe(data => {
      this.fileList = data;
      console.log("this.fileList MediaPanelComponent = " + this.fileList)
      err => console.log("errore : " + err)
       this.checkIfFotoAreAvailable();
    });
  }

  private checkIfFotoAreAvailable(){
    if(this.fileList.length==0){
      document.getElementById("fotoSorteggio").style.display = "none";
        document.getElementById("fotoSorteggioMsg").style.display = "inline";
    }
  }

  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };

  retriveAllClienti() {
    this.clientiService.getAllClientiAvailable().subscribe(data => {
      this.clientiList = data;
      console.log("clientiList : " + data);
      if (this.clientiList.length == 0) {
        document.getElementById("vicitoreRandom").style.display = "none";
        document.getElementById("clienteNotPresent").style.display = "inline";
      }
    })
  }


  isVicitoreRandomTrue() {
    document.getElementById("vicitoreRandom").style.display = "none";
    localStorage.setItem('vicitoreRandomStr', "none");
  }

}

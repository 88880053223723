import { Component, OnInit } from '@angular/core';
import { ResponsePojo } from '../../model/ResponsePojo';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { OrarioService } from '../../service/orario.service';
import { OrariPOJO } from '../../model/OrariPojo';

@Component({
  selector: 'orari',
  templateUrl: './orari.component.html',
  styleUrls: ['./orari.component.css']
})
export class OrariComponent implements OnInit {
  orariList: OrariPOJO[];
  res: ResponsePojo = new ResponsePojo();
  constructor(private service: OrarioService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }


  ngOnInit(): void {
    this.getAllOrari();
  }

  private getAllOrari() {
    this.service.getAllOrari().subscribe(data => {
      this.orariList = data;
      console.log("contattiList : " + data);
    })
  }

  private deteleOrarioById(id) {
    this.service.deteleOrarioById(id).subscribe((data: ResponsePojo) =>  {
      this.res = data;
      console.log("contattoDaCancellare : " + data);
      this.getAllOrari();
      
      if (this.res.messaggio) {
        this.openSnackBarOK(this.res.messaggio);
      } else {
        this.openSnackBarKO(this.res.error)
      }
    })
  }





  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };
}

import { Component, Input, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/public-api';
import { ActivatedRoute, Router } from '@angular/router';
import { EventiPojo } from 'src/app/model/EventiPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { EventiServiceService } from 'src/app/service/eventi-service.service';
import { MethodUtil } from 'src/app/utility/methodUtil';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AddTestiPopUpComponent } from 'src/app/add-testi-pop-up/add-testi-pop-up.component';
import { DownloadMediaPojo } from 'src/app/model/DownloadMediaPojo';
import { LinguaPojo } from 'src/app/model/LinguaPojo';
import { ResponsePojoEventi } from 'src/app/model/ResponsePojoEventi';
import { Testi } from 'src/app/model/Testi';
import { MatDialog } from '@angular/material/dialog';
import { LinguaService } from 'src/app/service/lingua.service';
import { MediaService } from 'src/app/service/media.service';
import { TestiListService } from 'src/app/service/testi-list.service';
import { EditTestiPopUpComponent } from 'src/app/edit-testi-pop-up/edit-testi-pop-up.component';

@Component({
  selector: 'app-edit-eventi',
  templateUrl: './edit-eventi.component.html',
  styleUrls: ['./edit-eventi.component.css']
})
export class EditEventiComponent implements OnInit {
  public panelOpenState: boolean;
  res: ResponsePojo = new ResponsePojo();
  evento: EventiPojo = new EventiPojo();
  dataEvento: Date | null = null;
  resEv: ResponsePojoEventi = new ResponsePojoEventi();
  eventoNameComponent: string;
  nomeEventoXTest: String;
  @Input() component: String;
  fileList: DownloadMediaPojo[];
  messaggo: string;
  testo: Testi = new Testi();
  testiList: Testi[];
  linguaList: LinguaPojo[];
  idEventoMedia: string;
  idEventoTesto: string;
  formattedDate : string;
  constructor(private linguaService: LinguaService, private serviceTesti: TestiListService, private serviceEventi: EventiServiceService, private mediaService: MediaService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.evento.id = this.route.snapshot.params['id'];
    this.evento.nome = this.route.snapshot.params['nome'];
    this.evento.descrizione = this.route.snapshot.params['descrizione'];
    this.evento.dataEvento = new Date(this.route.snapshot.params['dataEvento']);
    this.formattedDate = this.evento.dataEvento.toLocaleDateString();
    this.evento.ora = this.route.snapshot.params['ora'];
    this.evento.luogo = this.route.snapshot.params['luogo'];
    this.getTestiList(this.evento.id.toString());
  }

  setDate(event: MatDatepickerInputEvent<Date>) {
    this.dataEvento = event.value;
  }

  editEvento(): ResponsePojo {
    this.res = new ResponsePojo();
    this.serviceEventi.editEvento(this.evento).subscribe(data => {
      console.log("editTesti : " + data);
      this.res = data;
      this.openSnackBarOK(this.res.messaggio);
      this.goToEventi();
    },
      error => this.res.error);
    return this.res;
  }


  onSubmitEvento() {
    console.log(this.evento)
    this.editEvento();
  }
  goToEventi() {
    this.router.navigate([MethodUtil.previousLink("")]);
  }



  //------ MEDIA STRART------
  getAllMedia(mediaType: string) {
    this.component = "Eventi-" + this.evento.id;
    this.mediaService.getAllMedia(this.component, mediaType).subscribe(data => {
      this.fileList = data;
      console.log("this.fileList MediaPanelComponent = " + this.fileList)
      err => console.log("errore : " + err)
    });
  }


  deleteMedia(nomeMedia: string, mediaType: string) {
    this.mediaService.deteleMedia('Eventi-' + this.evento.id, nomeMedia, mediaType).subscribe(data => {
      console.log("deleteMedia " + "nomeMedia : " + nomeMedia + " mediaType : " + mediaType)
      this.res = data;
      console.log("data : " + data)
      console.log("this.messaggo : " + this.res.messaggio)
      if (this.res.messaggio) {
        this.openSnackBarOK(nomeMedia);
        MethodUtil.customDelay();
      }
      err => this.openSnackBarKO(err);
      MethodUtil.customDelay();
    });
  }


  openMediaSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio + " cancellato con successo", "", this.configSuccess);
  }

  openMediaSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  checkNavigationPath() {
    let path = this.router.getCurrentNavigation();
    console.log(path);
  }


  //----MEDIA END-----


  //------Testi component Start 


  openDialog(): void {
    this.clearTesti();
    this.testo.area = MethodUtil.removeDoubleQuotes(this.evento.nome);
    const dialogRef = this.dialog.open(AddTestiPopUpComponent, {
      width: '400px',
      height: '600px',
      data: this.testo
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.addTesti();

    });
  }
  clearTesti() {
    //  this.testo.id = null;
    this.testo.lingua = null;
    this.testo.nomeLingua = null;
    this.testo.testo = null;
  }


  addTesti(): ResponsePojo {
    //TODO: aggiungere id Evento al model testi, creare un api getTestoByEventoId
    this.testo.eventoId = this.evento.id.toString();
    this.testo.area = this.evento.nome;
    localStorage.setItem('nomeLingua', JSON.stringify(this.testo.lingua.nomeLingua));
    localStorage.setItem('codLingua', JSON.stringify(this.testo.lingua.codLingua));
    localStorage.setItem('idLingua', JSON.stringify(this.testo.lingua.id));
    this.serviceTesti.addTesto(this.testo).subscribe((data: ResponsePojo) => {
      console.log("addTesti : " + data);
      this.res = data;
      this.openSnackBarOK(this.res.messaggio);
      this.getTestiList(this.testo.eventoId.toString());
    },
      error => console.log(error));
    return this.res;
  }
  //Add updateTesti
  onSubmit() {
    console.log(this.testo)
    this.addTesti();
  }



  private getAllLingue() {
    this.linguaService.getAllLingue().subscribe(data => {
      this.linguaList = data;
      console.log("linguaList : " + data);
    })
  }

  getTestiList(eventoId: string) {
    this.serviceTesti.getTestiListEventoId(eventoId).subscribe(data => {
      this.testiList = data;
      console.log("testiList : " + data);
    })
  }


  openDialogEditTesti(testo: Testi): void {
    testo.lingua.nomeLingua = MethodUtil.removeDoubleQuotes(localStorage.getItem('nomeLingua'));
    testo.lingua.codLingua = MethodUtil.removeDoubleQuotes(localStorage.getItem('codLingua'));
    testo.lingua.id = Number(MethodUtil.removeDoubleQuotes(localStorage.getItem('idLingua')));
    const dialogRef = this.dialog.open(EditTestiPopUpComponent, {
      width: '400px',
      height: '600px',
      data: testo
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.editTesti(testo);

    });
  }

  editTesti(testo: Testi) {
    this.res = new ResponsePojo();
    this.serviceTesti.editTesto(testo).subscribe(data => {
      console.log("editTesti : " + data);
      this.res = data;
      this.openSnackBarOK(this.res.messaggio);
      this.getTestiList(this.testo.eventoId.toString());
    },
      error => this.res.error);
  }

  deteleTesto(testoId: number) {
    this.serviceTesti.deteleTesto(testoId).subscribe(data => {
      console.log("testiDaCancellare : " + data);
      this.res = data;
      this.openSnackBarOK(this.res.messaggio);
      this.getTestiList(this.testo.eventoId.toString());
    },
      error => this.res.error
    );
  }


  //------Testi Component End----

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };






  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }




  private saveEventoOverTheSession(evento: EventiPojo) {
    localStorage.setItem('eventoId', JSON.stringify(this.evento.id));
    localStorage.setItem('nomeEvento', JSON.stringify(this.evento.nome));
    localStorage.setItem('descrizioneEvento', JSON.stringify(this.evento.descrizione));
    localStorage.setItem('dataEvento', JSON.stringify(this.evento.dataEvento));
    localStorage.setItem('oraEvento', JSON.stringify(this.evento.ora));
    localStorage.setItem('luogoEvento', JSON.stringify(this.evento.luogo));
  }


  private retrieveEventoOverTheSession(evento: EventiPojo) {
    this.evento.nome = MethodUtil.removeDoubleQuotes(localStorage.getItem('nomeEvento')).toString();
    this.evento.descrizione = MethodUtil.removeDoubleQuotes(localStorage.getItem('descrizioneEvento')).toString();
    this.evento.dataEvento = new Date(localStorage.getItem('dataEvento'));
    this.evento.ora = MethodUtil.removeDoubleQuotes(localStorage.getItem('oraEvento')).toString();
    this.evento.luogo = MethodUtil.removeDoubleQuotes(localStorage.getItem('luogoEvento')).toString();
  }
}

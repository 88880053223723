import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-escursioni',
  templateUrl: './escursioni.component.html',
  styleUrls: ['./escursioni.component.css']
})
export class EscursioniComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<div class="sidenav">
   <div class="login-main-text">
      <h2 style="text-align:center;">SorrisoTerme Portal</h2>
      <br>
      <img src="assets/logo-top.png" class="center">
      <br>
      <p style="text-align:center;">Login here to access</p>
   </div>
</div>
<div class="main">
   <div class="col-md-6 col-sm-12">
      <div class="login-form">
         <form id="accespanel" action="login" method="post"> 
            <div class="form-group">
               <label class="label">User Name</label>
               <input type="text" name="username" [(ngModel)]="user.username" class="form-control"  id="email" placeholder="User Name">
            </div>
            <div class="form-group">
               <label class="label">Password</label>
               <input type="password" name="password" [(ngModel)]="user.password" class="form-control" id="password" placeholder="Password">
            </div>
            <button type="submit" name="login" class="btn btn-black" (click)="doLogin()" >Login</button>
            <!-- <button type="submit" class="btn btn-secondary">Register</button> -->
         </form>
      </div> 
   </div>
</div> 
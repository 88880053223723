<h2 style="text-align: center;"> Lingua</h2>
<a routerLink="/add-lingua" routerLinkActive="active" class="nav-link">Aggiungi Lingua</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Nome Lingua</th>
            <th>Codice Lingua</th>
            <th>Modifica/Cancella Lingua</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let lingua of linguaList">
            <td>{{lingua.nomeLingua}}</td>
            <td>{{lingua.codLingua}}</td>
            <td> 
          
                <button (click) = "updateLingua(lingua)" class="btn btn-info"> Update </button>
                <button (click) = "deleteLingua(lingua.id)" class="btn btn-danger" style="margin-left: 10px;"> Delete </button>
            </td>   
        </tr>
    </tbody>
</table>
<app-footer></app-footer>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalVariable } from '../model/GlobalVariable';
import { Testi } from '../model/Testi';
import { ResponsePojo } from '../model/ResponsePojo'

@Injectable({
  providedIn: 'root'
})
export class TestiListService {

  constructor(private http: HttpClient) { }

  tokenStr = 'Bearer ' + GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization", this.tokenStr);
  endPoint = "/portal/text"

  getTestiList(component: string): Observable<Testi[]> {
    //TODO: aggiungi =>{headers}
    return this.http.get<Testi[]>(GlobalVariable.basedUrl + this.endPoint + "/getAllText/" + component,);
  }

  getTestiListEventoId(eventoId: string): Observable<Testi[]> {
    //TODO: aggiungi =>{headers}
    console.log("Url: eventoId " + eventoId + " " + GlobalVariable.basedUrl + this.endPoint + "/getTextByEventoIdAndLingua/" + eventoId + "/" + null)
    return this.http.get<Testi[]>(GlobalVariable.basedUrl + this.endPoint + "/getTextByEventoIdAndLingua/" + eventoId + "/" + null,);
  }

  addTesto(testo: Testi): Observable<ResponsePojo> {
    //TODO: aggiungi =>{headers}
    return this.http.post<ResponsePojo>(GlobalVariable.basedUrl + this.endPoint + "/addText", testo);
  }

  editTesto(testo: Testi): Observable<ResponsePojo> {
    //TODO: aggiungi =>{headers}
    return this.http.post<ResponsePojo>(GlobalVariable.basedUrl + this.endPoint + "/editText", testo);
  }

  deteleTesto(id: number): Observable<ResponsePojo> {
    //TODO: aggiungi =>{headers}
    return this.http.delete<ResponsePojo>(`${GlobalVariable.basedUrl + this.endPoint + "/deleteTextById"}/${id}`);
  }

  deleteTextByEventoId(eventoId: string): Observable<ResponsePojo> {
    //TODO: aggiungi =>{headers}
    return this.http.delete<ResponsePojo>(`${GlobalVariable.basedUrl + this.endPoint + "/deleteTextByEventoId"}/${eventoId}`);
  }

}

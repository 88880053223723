import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ContattiPojo } from 'src/app/model/ContattiPojo';
import { LinguaPojo } from 'src/app/model/LinguaPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { ContattiService } from 'src/app/service/contatti.service';
import { LinguaService } from 'src/app/service/lingua.service';

@Component({
  selector: 'app-lingua',
  templateUrl: './lingua.component.html',
  styleUrls: ['./lingua.component.css']
})
export class LinguaComponent implements OnInit {
  linguaList: LinguaPojo[];
  res: ResponsePojo = new ResponsePojo();


  constructor(private service: LinguaService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAllLingue();
  }

  private getAllLingue() {
    this.service.getAllLingue().subscribe(data => {
      this.linguaList = data;
      console.log("linguaList : " + data);
    })
  }

  private deleteLingua(id) {
    this.service.deleteLinguById(id).subscribe((data: ResponsePojo) =>  {
      this.res = data;
      console.log("contattoDaCancellare : " + data);
      this.getAllLingue();
      
      if (this.res.messaggio) {
        this.openSnackBarOK(this.res.messaggio);
      } else {
        this.openSnackBarKO(this.res.error)
      }
    })
  }

  updateLingua(lingua: LinguaPojo) {
    this.router.navigate(['edit-lingua', lingua]);
  }



  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };


}

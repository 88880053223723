import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadMediaPojo } from '../model/DownloadMediaPojo';
import { MediaService } from '../service/media.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { MethodUtil } from '../utility/methodUtil';
import { ResponsePojo } from '../model/ResponsePojo';
import { ChangeDetectionStrategy } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
@Component({
  selector: 'app-media-panel',
  templateUrl: './media-panel.component.html',
  styleUrls: ['./media-panel.component.css']
})
export class MediaPanelComponent implements OnInit {
  res: ResponsePojo = new ResponsePojo();
  public panelOpenState: boolean;
  @Input() component: string;
  fileList: DownloadMediaPojo[];
  messaggo: string;

  constructor(private service: MediaService, private http: HttpClient, private snackBar: MatSnackBar, private router: Router) { }
  ngOnInit(): void {
  }

  getAllMedia(mediaType: string) {
    this.service.getAllMedia(this.component, mediaType).subscribe(data => {
      this.fileList = data;
      console.log("this.fileList MediaPanelComponent = " + this.fileList)
      err => console.log("errore : " + err)
    });
  }


  deleteMedia(nomeMedia: string, mediaType: string) {
    this.service.deteleMedia(this.component, nomeMedia, mediaType).subscribe(data => {
      console.log("deleteMedia " + "nomeMedia : " + nomeMedia + " mediaType : " + mediaType)
      this.res = data;
      console.log("data : " + data)
      console.log("this.messaggo : " + this.res.messaggio)
      if (this.res.messaggio) {
        this.openSnackBarOK(nomeMedia);
        MethodUtil.customDelay();
      }
      err => this.openSnackBarKO(err);
      MethodUtil.customDelay();
    });
  }


  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio + " cancellato con successo", "", this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };
}

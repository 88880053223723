import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContattiPojo } from '../model/ContattiPojo';
import { GlobalVariable } from '../model/GlobalVariable';
import { ResponsePojo } from '../model/ResponsePojo';
import { Testi } from '../model/Testi';

@Injectable({
  providedIn: 'root'
})
export class ContattiService {

  constructor(private http : HttpClient) { }

  tokenStr = 'Bearer ' +  GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization",this.tokenStr);
  endPoint = "/portal/contatti"

  getAllContatti(): Observable<ContattiPojo[]>{
      //TODO: aggiungi =>{headers}
    return this.http.get<ContattiPojo[]>(GlobalVariable.basedUrl+this.endPoint+"/getAllContatti/", );
    }

    addContatti(contatti : ContattiPojo): Observable<ResponsePojo>{
      //TODO: aggiungi =>{headers}
      return this.http.post<ResponsePojo>(GlobalVariable.basedUrl+this.endPoint+"/addContatti",contatti);
    }

    editContatti(contatti : ContattiPojo): Observable<ResponsePojo>{        
      //TODO: aggiungi =>{headers}
      return this.http.post<ResponsePojo>(GlobalVariable.basedUrl+this.endPoint+"/editContatti", contatti);
    }

    deleteContattiById(id:number) : Observable<Object>{
       //TODO: aggiungi =>{headers}
    return this.http.delete(`${GlobalVariable.basedUrl+this.endPoint+"/deleteContattiById"}/${id}`);
    }    
}

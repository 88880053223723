import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LinguaPojo } from 'src/app/model/LinguaPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { LinguaService } from 'src/app/service/lingua.service';

@Component({
  selector: 'app-add-lingua',
  templateUrl: './add-lingua.component.html',
  styleUrls: ['./add-lingua.component.css']
})
export class AddLinguaComponent implements OnInit {

  lingua: LinguaPojo = new LinguaPojo();
  res: ResponsePojo = new ResponsePojo();
  
  constructor(private service: LinguaService, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
      
  }

  addLingua(): ResponsePojo {
    this.service.addLingua(this.lingua).subscribe((data: ResponsePojo) => {
      console.log("addLingua : " + data);
      this.res = data;
      this.goToLingua();
    },
      error => console.log(error));

    return this.res;
  }

  onSubmit() {
    console.log(this.lingua)
    this.addLingua();
  }

  goToLingua() {
    this.router.navigate([ "configurazione"]);
    if (this.res.messaggio) {
      this.openSnackBarOK(this.res.messaggio);
    } else {
      this.openSnackBarKO(this.res.error)
    }
  }

  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };

}

<app-menu></app-menu>
<h1 style="text-align: center;"> Sorteggio</h1>
<a routerLink="/add-sorteggio" routerLinkActive="active" class="nav-link">Aggiungi Sorteggio</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Nome Sorteggio</th>
            <th>Data Creazione Sorteggio</th>
            <th>Sorteggio Attivo</th>
            <th>Vincitore Random</th>
            <th>Modifica/Cancella Sorteggio</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let sorteggio of sorteggioList">
            <td>{{sorteggio.nomeSorteggio}}</td>
            <td>{{sorteggio.dataCreazioneSorteggio}}</td>
             <td>{{sorteggio.sorteggioAttivo}}</td>
             <td>{{sorteggio.vincitoreRandom}}</td>
            <td> 
                <button (click) = "updateSorteggio(sorteggio)" class="btn btn-info"> Update </button>
                <button (click) = "deteleSorteggioById(sorteggio.id)" class="btn btn-danger" style="margin-left: 10px;"> Delete </button>
            </td>   
        </tr>
    </tbody>
</table>
<app-footer></app-footer>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from '../model/GlobalVariable';
import { OffertaPojo } from '../model/OffertaPojo';
import { Observable } from 'rxjs';
import { ResponsePojo } from '../model/ResponsePojo';

@Injectable({
  providedIn: 'root'
})
export class OfferteService {
  constructor(private http : HttpClient) { }

  tokenStr = 'Bearer ' +  GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization",this.tokenStr);
  endPoint = "/portal/offerte"
  

  getAllOfferte(): Observable<OffertaPojo[]>{
    //TODO: aggiungi =>{headers}
  return this.http.get<OffertaPojo[]>(GlobalVariable.basedUrl+this.endPoint+"/getAllOfferte/", );
  }

  addOfferta(offerta : OffertaPojo): Observable<ResponsePojo>{
    //TODO: aggiungi =>{headers}
    return this.http.post<ResponsePojo>(GlobalVariable.basedUrl+this.endPoint+"/addOfferta",offerta);
  }

  editOfferta(offerta : OffertaPojo): Observable<ResponsePojo>{        
    //TODO: aggiungi =>{headers}
    return this.http.post<ResponsePojo>(GlobalVariable.basedUrl+this.endPoint+"/editOfferta", offerta);
  }

  deteleOffertaById(id:number) : Observable<Object>{
     //TODO: aggiungi =>{headers}
  return this.http.delete<ResponsePojo>(`${GlobalVariable.basedUrl+this.endPoint+"/deleteOffertaById"}/${id}`);
  }   

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtClientService } from '../service/jwt-client.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
welcomeStr: any;
  constructor(private service : JwtClientService, private router : Router) { }

  ngOnInit(): void {
   this.welcome()
  }



  welcome() {
  let resp=  this.service.home();
    resp.subscribe((data: any) => {
      this.welcomeStr=data;
      console.log(data);
    },
      (     error: any) => console.log(error));
  }

}

import { Observable } from "rxjs/internal/Observable";
import { GlobalVariable } from "../model/GlobalVariable";
import { SorteggioCliente } from "../model/SorteggioCliente";
import { SorteggioPojo } from "../model/SorteggioPojo";

export class MethodUtil {
  static customDelay() {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };


  static previousLink(area: String): string {
    switch (area) {
      case "RistoranteOasis":
        return '/ristoranteOasis';
        break;
      case "RistoranteHotel":
        return '/ristoranteHotel';
        break;
      case "SPA":
        return '/spa';
        break;
      case "Piscine":
        return '/piscine';
        break;
      case "PoolBar":
        return '/pool-bar';
        break;
      case "BluBar":
        return '/blu-bar';
        break;
      case "SPA":
        return '/spa';
        break;
      case "Piscine":
        return '/piscine';
        break;
      case "Navetta":
        return '/navetta';
        break;
      case "Escursioni":
        return '/escursioni';
        break;
      case "Noleggio":
        return '/noleggio';
        break;
      case "Eventi":
        return '/eventi';
        break;
      case "Orari":
        return '/orari';
        break;
 
    }
  }

  static retriveEventoNameComponent(component: String): String {
    if (GlobalVariable.eventoNameComponent != null) {
      component = GlobalVariable.eventoNameComponent;
      component = component.trim();
      component = component.replace(/\s/g, "");
    }
    return component;
  }


  static removeDoubleQuotes(stringToClean: String): String {
    let cleanedString: String;
    if (stringToClean != null) {
      cleanedString = stringToClean.replace("\"", "");
      cleanedString = cleanedString.replace("\"", "");
    }

    return cleanedString;
  }


  static mapSorteggioTOSorteggioCliente(sorteggio: SorteggioPojo): SorteggioCliente {
    let sorc: SorteggioCliente;
    sorc.id = sorteggio.id;
    sorc.nomeSorteggio = sorteggio.nomeSorteggio
    sorc.nomeFoto = sorteggio.nomeFoto
    sorc.dataCreazioneSorteggio = sorteggio.dataCreazioneSorteggio
    sorc.sorteggioAttivo = sorteggio.sorteggioAttivo
    sorc.vincitoreRandom = sorteggio.vincitoreRandom
    sorc.idCliente = sorteggio.cliente.id
    sorc.nome = sorteggio.cliente.nome
    sorc.cognome = sorteggio.cliente.cognome
    sorc.mail = sorteggio.cliente.mail
    sorc.dataInizio = sorteggio.cliente.dataInizio
    sorc.dataFine = sorteggio.cliente.dataFine
    return sorc;

  }


}
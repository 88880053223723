import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventiPojo } from 'src/app/model/EventiPojo';
import { EventiServiceService } from 'src/app/service/eventi-service.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ResponsePojoEventi } from 'src/app/model/ResponsePojoEventi';
import { TestiListService } from 'src/app/service/testi-list.service';
import { MediaService } from 'src/app/service/media.service';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
@Component({
  selector: 'app-eventi',
  templateUrl: './eventi.component.html',
  styleUrls: ['./eventi.component.css']
})
export class EventiComponent implements OnInit {
  resMedia: ResponsePojo = new ResponsePojoEventi();
  resTest: ResponsePojo = new ResponsePojoEventi();
  resEv: ResponsePojoEventi = new ResponsePojoEventi();
  eventiList: EventiPojo[];

  constructor(private eventiService: EventiServiceService, private testiService: TestiListService, private mediaService: MediaService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    localStorage.clear();
    this.getEventiList();
  }


  private getEventiList() {
    this.eventiService.getEventiList().subscribe(data => {
      this.eventiList = data;
      console.log("eventiList : " + data);
    })
  }



  deteleEvento(evento: EventiPojo): ResponsePojoEventi {
  
    this.mediaService.deteleMediaByEventoId("Eventi", evento.id).subscribe(data => {
     this.resMedia = data;
    },
      error => {
        if (error != null) {
          this.openSnackBarKO(error);
        }
      });
    this.testiService.deleteTextByEventoId(evento.id).subscribe(data => {
      this.resTest = data;
    },
      error => {
        if (error != null) {
          this.openSnackBarKO(error);
        }
      });


    if (this.resMedia.messaggio != null && this.resTest.messaggio != null && this.resMedia.error == null && this.resTest.error == null) {
      this.eventiService.deteleEvento(evento.id).subscribe(data => {
        console.log("eventoDaCancellare : " + data);
        this.resEv = data;
        this.openSnackBarOK(this.resEv.messaggio);
        this.getEventiList();
      },
        error => {
          if (error != null) {
            this.openSnackBarKO(error);
          }
        });
    }

    return this.resEv;
  }

  updateEvento(evento: EventiPojo) {
    // GlobalVariable.lingua = testi.lingua
    this.router.navigate(['edit-eventi', evento]);
    // this.router.navigate(['edit-testi', testi.lingua]);
  }



  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };






  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }


}

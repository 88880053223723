<app-menu></app-menu>
<h2 style="text-align: center;"> Orari</h2>
<a routerLink="/add-orari" routerLinkActive="active" class="nav-link">Aggiungi Orari</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th>Nome Orario</th>
            <th>Orario</th>
            <th>Codice Lingua</th>
            <th>Cancella Orario</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let orario of orariList">
            <td>{{orario.nomeOrario}}</td>
            <td>{{orario.orario}}</td>
             <td>{{orario.codiceLingua}}</td>
            <td> 
                <button (click) = "deteleOrarioById(orario.id)" class="btn btn-danger" style="margin-left: 10px;"> Delete </button>
            </td>   
        </tr>
    </tbody>
</table>
<app-footer></app-footer>
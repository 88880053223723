<app-menu></app-menu>
<h1 style="text-align: center;"> Mappe</h1>
<!-- <app-media-panel-two-pdf component="Mappe" pdfName="mappe" fileType="MAPPE"></app-media-panel-two-pdf> -->
<h2 style="text-align: center;"> Upload file</h2>
<mat-accordion>
    <mat-expansion-panel (opened)="getAllMedia('MAPPA-ALBERGO')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui la mappa albergo
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon>map</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType=".pdf" fileType="MAPPA-ALBERGO" frase="Nessuna foto selezionata"
            component="Mappe"></app-upload-file>
        <br>

        <mat-grid-list cols="4" rowHeight="300px">
            <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>{{file.nome}}</mat-card-title>
                    </mat-card-header>
                    <p><a href={{file.url}}>{{file.nome}}</a></p>
                    <mat-card-actions>
                        <button mat-button (click)="deleteMedia(file.nome,'MAPPA-ALBERGO')">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
        <br>
        <app-notifiche component="MAPPA-ALBERGO"></app-notifiche>
        <br>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="getAllMedia('MAPPA-ISCHIA')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui la mappa Ischia
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon>map</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType=".pdf" fileType="MAPPA-ISCHIA" frase="Nessun video selezionato"
            component="Mappe"></app-upload-file>
        <br>
        <mat-grid-list cols="4" rowHeight="600px">
            <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>{{file.nome}}</mat-card-title>
                    </mat-card-header>
                    <p><a href={{file.url}}>{{file.nome}}</a></p>
                    <mat-card-actions>
                        <button mat-button (click)="deleteMedia(file.nome,'MAPPA-ISCHIA').click">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
        <br>
        <app-notifiche component="MAPPA-ISCHIA"></app-notifiche>
        <br>
    </mat-expansion-panel>
</mat-accordion>
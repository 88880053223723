<app-menu></app-menu>
<div class="col-md-8 offset-md-3" style="overflow-y: scroll; height: 800px;">

    <h3> Modifica Testo </h3>
    <form (ngSubmit)="onSubmit()">

        <div class="form-group">
            <label>Area</label>
            <input type="text" class="form-control" id="area" [(ngModel)]="testo.area" name="area">
        </div>

        <div class="form-group">
            <label>Testo</label>
            <input type="text" class="form-control" id="testo" [(ngModel)]="testo.testo" name="testo">
        </div>

        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Lingua</mat-label>
                <mat-select [(value)]="testo.nomeLingua">
                    <mat-option *ngFor="let lingue of linguaList" [value]="lingue.nomeLingua">
                        {{lingue.nomeLingua}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <button class="btn btn-success" type="submit">Submit</button>

    </form>
</div>
<app-footer></app-footer>
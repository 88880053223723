import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContattiPojo } from '../model/ContattiPojo';
import { GlobalVariable } from '../model/GlobalVariable';
import { LinguaPojo } from '../model/LinguaPojo';
import { ResponsePojo } from '../model/ResponsePojo';

@Injectable({
  providedIn: 'root'
})
export class LinguaService {

  constructor(private http : HttpClient) { }

  tokenStr = 'Bearer ' +  GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization",this.tokenStr);
  endPoint = "/portal/lingua"

  getAllLingue(): Observable<LinguaPojo[]>{
      //TODO: aggiungi =>{headers}
    return this.http.get<LinguaPojo[]>(GlobalVariable.basedUrl+this.endPoint+"/getAllLingue/", );
    }

    addLingua(lingua : LinguaPojo): Observable<ResponsePojo>{
      //TODO: aggiungi =>{headers}
      return this.http.post<ResponsePojo>(GlobalVariable.basedUrl+this.endPoint+"/addLingua",lingua);
    }

    editLingua(lingua : LinguaPojo): Observable<ResponsePojo>{        
      //TODO: aggiungi =>{headers}
      return this.http.post<ResponsePojo>(GlobalVariable.basedUrl+this.endPoint+"/editLingua", lingua);
    }

    deleteLinguById(id:number) : Observable<Object>{
       //TODO: aggiungi =>{headers}
    return this.http.delete(`${GlobalVariable.basedUrl+this.endPoint+"/deleteLinguById"}/${id}`);
    }    
}

import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LinguaPojo } from '../model/LinguaPojo';
import { ResponsePojo } from '../model/ResponsePojo';
import { Testi } from '../model/Testi';
import { LinguaService } from '../service/lingua.service';
import { TestiListService } from '../service/testi-list.service';
import { MethodUtil } from '../utility/methodUtil';

@Component({
  selector: 'app-add-testi-pop-up',
  templateUrl: './add-testi-pop-up.component.html',
  styleUrls: ['./add-testi-pop-up.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddTestiPopUpComponent implements OnInit {
  linguaList: LinguaPojo[];
  res: ResponsePojo = new ResponsePojo();
  constructor(
    public dialogRef: MatDialogRef<AddTestiPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public testo: Testi, private linguaService: LinguaService, private service: TestiListService
  ) { }


  ngOnInit(): void {
    const nomeEvento = localStorage.getItem('nomeEvento');
    if (nomeEvento != null) {
      this.testo.area = MethodUtil.removeDoubleQuotes(localStorage.getItem('nomeEvento'));
    }
    this.getAllLingue();
    if (this.testo.lingua == null) {
      this.testo.lingua = new LinguaPojo();
    }
    
    // this.testo.lingua.nomeLingua = MethodUtil.removeDoubleQuotes(localStorage.getItem('nomeLingua'));
    // this.testo.lingua.codLingua = MethodUtil.removeDoubleQuotes(localStorage.getItem('codLingua'));
    // this.testo.lingua.id = Number(MethodUtil.removeDoubleQuotes(localStorage.getItem('idLingua')));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  private getAllLingue() {
    this.linguaService.getAllLingue().subscribe(data => {
      this.linguaList = data;
      console.log("linguaList : " + data);
    })
  }


  onSubmit() {
    console.log(this.testo)
    this.onNoClick()
  }
}

<app-menu></app-menu>

<div class="col-md-8 offset-md-3" style="overflow-y: scroll; height: 800px;">

    <h3> Aggiungi Contatti </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Nome Contatto</label>
            <input type="text" class="form-control" id="area" [(ngModel)]="contatto.nomeContatto" name="nomeContatto">
        </div>

        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Tipologia Contatto</mat-label>
                <mat-select [(value)]="contatto.tipologiaContatto">
                    <mat-option *ngFor="let contattiValue of contattiValueList" [value]="contattiValue.value">
                        {{contattiValue.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-group">
            <label>Valore Contatto</label>
            <input type="text" class="form-control" id="lingua" [(ngModel)]="contatto.valoreContatto"
                name="valoreContatto">
        </div>

        <button class="btn btn-success" type="submit">Submit</button>


    </form>
</div>
<app-footer></app-footer>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { GlobalVariable } from '../model/GlobalVariable';
import { User } from '../model/User';
import { ResponsePojo } from '../model/ResponsePojo';
import { LoginPojo } from '../model/LoginPojo';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(private http: HttpClient) { }


login(user: LoginPojo): Observable<any> {
    console.log("username = " + user.username, " password = " + user.password)
    /*const headers = new HttpHeaders({Authorization: 'Basic '+btoa(username+":"+password)});
    headers.append("Access-Control-Allow-Origin",GlobalVariable.basedUrlFE+"");
    headers.append("Access-Control-Allow-Methods", "GET, DELETE, HEAD, OPTIONS");
    return this.http.post(GlobalVariable.basedUrl+"/login", { headers,responseType : 'text' as 'json'});*/
    return this.http.post<ResponsePojo>(GlobalVariable.basedUrl + "/user-login", user);


  }
}

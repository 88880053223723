import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVariable } from 'src/app/model/GlobalVariable';
import { LinguaPojo } from 'src/app/model/LinguaPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { Testi } from 'src/app/model/Testi';
import { LinguaService } from 'src/app/service/lingua.service';
import { TestiListService } from 'src/app/service/testi-list.service';
import { MethodUtil } from 'src/app/utility/methodUtil';
import { Location } from '@angular/common';
@Component({
  selector: 'app-add-testi',
  templateUrl: './add-testi.component.html',
  styleUrls: ['./add-testi.component.css']
})
export class AddTestiComponent implements OnInit {
  testo: Testi = new Testi();
  linguaList: LinguaPojo[];
  res: ResponsePojo = new ResponsePojo();
  component: string;
  constructor(private service: TestiListService, private linguaService: LinguaService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, private location: Location) { }

  ngOnInit(): void {
    this.testo.area = MethodUtil.retriveEventoNameComponent(GlobalVariable.component);
    this.getAllLingue();
  }

  addTesti(): ResponsePojo {
    this.testo.nomeLingua = this.testo.lingua.nomeLingua
    this.testo.codLingua = this.testo.lingua.codLingua
    this.service.addTesto(this.testo).subscribe((data: ResponsePojo) => {
      console.log("addTesti : " + data);
      this.res = data;
      this.goToTesti();
    },
      error => console.log(error));

    return this.res;
  }

  onSubmit() {
    console.log(this.testo)
    this.addTesti();
  }



  private getAllLingue() {
    this.linguaService.getAllLingue().subscribe(data => {
      this.linguaList = data;
      console.log("linguaList : " + data);
    })
  }

  goToTesti() {
    this.router.navigate([MethodUtil.previousLink(this.testo.area)]);
    if (this.res.messaggio) {
      this.openSnackBarOK(this.res.messaggio);
    } else {
      this.openSnackBarKO(this.res.error)
    }
  }

  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };

}

<h2 style="text-align: center;"> Upload file</h2>
<mat-accordion>
    <mat-expansion-panel (opened)="getAllMedia('FOTO')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui le foto
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon>photo_camera</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType="image/*" fileType="FOTO" frase="Nessuna foto selezionata"
            component={{component}}></app-upload-file>
        <br>

        <!--  <mat-grid-list cols="4" rowHeight="300px">
                <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">  
                    <mat-card class="example-card">-->
        <div class="cdk-virtual-scroll-data-source-example">
            <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="50" class="example-viewport">

                <div *cdkVirtualFor="let file of fileList" class="example-item">

                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{file.nome}}</mat-card-title>
                        </mat-card-header>
                        <img mat-card-image src={{file.url}} alt={{file.nome}}>
                        <mat-card-actions>
                            <button mat-button (click)="deleteMedia(file.nome,'FOTO')">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
        <!-- </mat-grid-tile>
        </mat-grid-list>  -->
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="getAllMedia('VIDEO')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui i video
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon>videocam</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType="video/*" fileType="VIDEO" frase="Nessun video selezionato"
            component={{component}}></app-upload-file>
        <br>
        <mat-grid-list cols="4" rowHeight="600px">
            <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>{{file.nome}}</mat-card-title>
                    </mat-card-header>
                    <video width="250" controls>
                        <source src={{file.url}}>
                    </video>
                    <mat-card-actions>
                        <button mat-button (click)="deleteMedia(file.nome,'VIDEO').click">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="getAllMedia('MENU')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui il menu
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon>restaurant_menu</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType=".pdf" fileType="MENU" frase="Nessun menù selezionato"
            component={{component}}></app-upload-file>
        <br>
        <!-- <app-card-media fileType="MENU"></app-card-media> -->
        <mat-grid-list cols="4" rowHeight="300px">
            <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                <mat-card class="example-card">
                    <mat-card-header>
                        <mat-card-title>{{file.nome}}</mat-card-title>
                    </mat-card-header>
                    <!-- <img mat-card-image href={{file.url}} alt={{file.nome}}> -->
                    <p><a href={{file.url}}>{{file.nome}}</a></p>
                    <mat-card-actions>
                        <button mat-button (click)="deleteMedia(file.nome,'MENU')">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-expansion-panel>


    <mat-expansion-panel (opened)="getAllMedia('LISTINO')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica qui il listino
            </mat-panel-title>
            <mat-panel-description>
                <p></p>
                <mat-icon> euro_symbol </mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType=".pdf" fileType="LISTINO" frase="Nessun listino selezionato"
            component={{component}}>
        </app-upload-file>
        <br>
        <!-- <app-card-media fileType="LISTINO"></app-card-media> -->
        <mat-grid-list cols="4" rowHeight="300px">
            <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                <mat-card class="example-card" *ngFor="let file of fileList">
                    <mat-card-header>
                        <mat-card-title>{{file.nome}}</mat-card-title>
                    </mat-card-header>
                    <!-- <img mat-card-image href={{file.url}} alt={{file.nome}}> -->
                    <p><a href={{file.url}}>{{file.nome}}</a></p>
                    <mat-card-actions>
                        <button mat-button (click)="deleteMedia(file.nome,'LISTINO')">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-expansion-panel>


    <mat-expansion-panel (opened)="getAllMedia('LOCANDINE')" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Carica la locandina
            </mat-panel-title>
            <mat-panel-description>
                <mat-icon>
                    article
                </mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-upload-file requiredFileType=".pdf" fileType="LOCANDINE" frase="Nessua locandina selezionata"
            component={{component}}>
        </app-upload-file>
        <br>
        <!-- <app-card-media fileType="LISTINO"></app-card-media> -->
        <mat-grid-list cols="4" rowHeight="300px">
            <mat-grid-tile *ngFor="let file of fileList" [rowspan]="1">
                <mat-card class="example-card" *ngFor="let file of fileList">
                    <mat-card-header>
                        <mat-card-title>{{file.nome}}</mat-card-title>
                    </mat-card-header>
                    <!-- <img mat-card-image href={{file.url}} alt={{file.nome}}> -->
                    <p><a href={{file.url}}>{{file.nome}}</a></p>
                    <mat-card-actions>
                        <button mat-button (click)="deleteMedia(file.nome,'LOCANDINE')">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-expansion-panel>

</mat-accordion>
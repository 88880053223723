<div class="col-md-8 offset-md-3" style="overflow-y:hidden; height: 800px;width: 300px;z-index: 99999 !important">

    <h3> Aggiungi Testo </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Area</label>
            <input type="text" class="form-control" id="area" [(ngModel)]="testo.area" name="area">
        </div>

        <div class="form-group">
            <label>Testo</label>
            <input type="text" class="form-control" id="testo" [(ngModel)]="testo.testo" name="testo">
        </div>
        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Lingua</mat-label>
                <mat-select [(value)]="testo.lingua">
                    <mat-option *ngFor="let lingue of linguaList" [value]="lingue">
                        {{lingue.nomeLingua}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <br>
        <button class="btn btn-success" type="submit">Submit</button>


    </form>
</div>
<app-menu></app-menu>

<div class="col-md-8 offset-md-3" style="height: 800px;">
<br>
    <h3> Aggiungi Offerta </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Nome Offerta</label>
            <input type="text" class="form-control" id="nomeOfferta" [(ngModel)]="offerta.nomeOfferta" name="nomeOfferta">
        </div>
        <div class="form-group">
            <label>Attiva Offerta</label>
            <input type="checkbox" class="form-control" id="attiva" name="attiva" [(ngModel)]="offerta.attiva"  checked="checked">
        </div>
      
        <div class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" (dateInput)="setDate($event)">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="form-group">
            <label>Link Offerta sul sito</label>
            <input type="text" class="form-control" id="linkOfferta" name="linkOfferta" [(ngModel)]="offerta.link"  >
        </div>

        <br>
        <div id="fotoOfferte" class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Foto Offerte</mat-label>
                <mat-select [(value)]="offerta.nomeFotoOfferta">
                    <mat-option *ngFor="let file of fileList" [value]="file.nome">
                       <b>Nome foto:</b> {{file.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <br>
        
       <button class="btn btn-success" type="submit">Submit</button>
    </form>
</div>
<app-footer></app-footer>
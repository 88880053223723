import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventiPojo } from '../model/EventiPojo';
import { GlobalVariable } from '../model/GlobalVariable';
import { ResponsePojo } from '../model/ResponsePojo';
import { ResponsePojoEventi } from '../model/ResponsePojoEventi';

@Injectable({
  providedIn: 'root'
})
export class EventiServiceService {

  constructor(private http: HttpClient) { }

  tokenStr = 'Bearer ' + GlobalVariable.token;
  headers = new HttpHeaders().set("Authorization", this.tokenStr);
  endPoint = "/portal/eventi"


  addEvento(evento: EventiPojo): Observable<ResponsePojoEventi> {
    //TODO: aggiungi =>{headers}
    return this.http.post<ResponsePojoEventi>(GlobalVariable.basedUrl + this.endPoint + "/addEventi", evento);
  }

  getEventiList(): Observable<EventiPojo[]>{
    //TODO: aggiungi =>{headers}
  return this.http.get<EventiPojo[]>(GlobalVariable.basedUrl+this.endPoint+"/getAllEventi" );
  }

  editEvento(evento: EventiPojo): Observable<ResponsePojoEventi>{        
    //TODO: aggiungi =>{headers}
    return this.http.post<ResponsePojoEventi>(GlobalVariable.basedUrl+this.endPoint+"/editEventi", evento);
  }

  deteleEvento(id:string) : Observable<ResponsePojoEventi>{
     //TODO: aggiungi =>{headers}
  return this.http.delete<ResponsePojoEventi>(`${GlobalVariable.basedUrl+this.endPoint+"/deleteEventoById"}/${id}`);
  }  

}

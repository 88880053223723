<app-menu></app-menu>

<div class="col-md-8 offset-md-3" style="height: 800px;">
<br>
    <h3> Aggiungi Sorteggio </h3>
    <form (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Nome Sorteggio</label>
            <input type="text" class="form-control" id="nomeSorteggio" [(ngModel)]="sorteggio.nomeSorteggio" name="nomeSorteggio">
        </div>
        <div class="form-group">
            <label>Attiva Sorteggio</label>
            <input type="checkbox" class="form-control" id="sorteggioAttivo" name="sorteggioAttivo" [(ngModel)]="sorteggio.sorteggioAttivo"  checked="checked">
        </div>
        <div class="form-group">
            <label>Vincitore Random</label>
            <input type="checkbox" class="form-control" id="sorteggioAttivo" [(ngModel)]="sorteggio.vincitoreRandom" name="vincitoreRandom" (click)="isVicitoreRandomTrue()">
        </div>

        <div id=vicitoreRandom class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Cliente</mat-label>
                <mat-select [(value)]="sorteggio.cliente">
                    <mat-option *ngFor="let cliente of clientiList" [value]="cli">
                       <b>Cliente:</b> {{cli.nome}} 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div id="clienteNotPresent"  style="display: none;">
            <label style="color: red;text-align: center;">!!! ATTENZIONE !!! - Nessun cliente disponibile</label>
        </div>
        <br>
        <br>
        <div id="fotoSorteggioMsg"  style="display: none;">
            <label style="color: red;text-align: center;">!!! ATTENZIONE !!! - Nessuna foto presente, aggiungere foto in configurazione</label>
        </div>

        <br>
        <div id="fotoSorteggio" class="form-group">
            <mat-form-field appearance="fill">
                <mat-label>Foto Sorteggio</mat-label>
                <mat-select [(value)]="sorteggio.nomeFoto">
                    <mat-option *ngFor="let file of fileList" [value]="file.nome">
                       <b>Nome foto:</b> {{file.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <br>
        
       <button class="btn btn-success" type="submit">Submit</button>
    </form>
</div>
<app-footer></app-footer>
export class

    NotifichePojo {
    id: number;
    area: String;
    testo: String;
    codLingua: String;
    oraNotifica: number;
    minutiNotifica: number;
    repeats: boolean;
    giornoSettimana: String;
    dataNotifica: Date;
}
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { OffertaPojo } from 'src/app/model/OffertaPojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { OfferteService } from 'src/app/service/offerte.service';

@Component({
  selector: 'app-offerte-list',
  templateUrl: './offerte-list.component.html',
  styleUrls: ['./offerte-list.component.css']
})
export class OfferteListComponent implements OnInit {
  offerteList: OffertaPojo[];
  res: ResponsePojo = new ResponsePojo();
  constructor(private service: OfferteService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAllOfferte();
  }


  private getAllOfferte() {
    this.service.getAllOfferte().subscribe(data => {
      this.offerteList = data;
      console.log("offerteList : " + data);
    })
  }

  deteleOffertaById(id: number) {
    console.log("offerta ID DaCancellare : " + id);
    this.service.deteleOffertaById(id).subscribe((data: ResponsePojo) => {
      this.res = data;
      console.log("offertaDaCancellare : " + data);
      this.getAllOfferte();

      if (this.res.messaggio) {
        this.openSnackBarOK(this.res.messaggio);
      } else {
        this.openSnackBarKO(this.res.error)
      }
    })
  }


  updateOfferta(offerta: OffertaPojo) {
    this.router.navigate(['edit-offerta', offerta]);
  }

  openSnackBarOK(messaggio: string) {
    console.log("openSnackBarOK " + "messaggio : " + messaggio)
    this.snackBar.open(messaggio, null, this.configSuccess);
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: ['snackBar-style-success'],
    duration: 2000
  };


  openSnackBarKO(messaggio: string) {
    this.snackBar.open(messaggio, null, this.configError);
  }

  configError: MatSnackBarConfig = {
    panelClass: ['snackBar-style-error'],
    duration: 2000
  };


}

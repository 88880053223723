import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContattiPojo } from 'src/app/model/ContattiPojo';
import { ContattiValuePojo } from 'src/app/model/ContattiValuePojo';
import { ResponsePojo } from 'src/app/model/ResponsePojo';
import { Testi } from 'src/app/model/Testi';
import { ContattiService } from 'src/app/service/contatti.service';
import { TestiListService } from 'src/app/service/testi-list.service';

@Component({
  selector: 'app-edit-contatti',
  templateUrl: './edit-contatti.component.html',
  styleUrls: ['./edit-contatti.component.css']
})
export class EditContattiComponent implements OnInit {
  contattiValueList: ContattiValuePojo[] = [
    { value: 'email', viewValue: 'Email' },
    { value: 'telefono', viewValue: 'Telefono' },
    { value: 'cellulare', viewValue: 'Cellulare' },
    { value: 'sito', viewValue: 'Sito' }
  ];
  contattiValue: ContattiValuePojo = new ContattiValuePojo();
  contatto: ContattiPojo = new ContattiPojo();
  res: ResponsePojo = new ResponsePojo();
  constructor(private service: ContattiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.contatto.id = this.route.snapshot.params['id'];
    this.contatto.nomeContatto = this.route.snapshot.params['nomeContatto'];
    this.contatto.tipologiaContatto = this.route.snapshot.params['tipologiaContatto'];
    this.contatto.valoreContatto = this.route.snapshot.params['valoreContatto'];
  }

  editContatti(): ResponsePojo {
    this.res = new ResponsePojo();
    this.service.editContatti(this.contatto).subscribe(data => {
      console.log("editTesti : " + data);
      data => this.res.messaggio;
      this.goToContatti();
    },
      error => this.res.error);
    return this.res;
  }

  goToContatti() {
    this.router.navigate(['/contatti']);
  }

  onSubmit() {
    console.log(this.contatto)
    this.editContatti();
  }
}
